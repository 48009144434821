import {
  Anchor,
  Button,
  Card,
  Group,
  HoverCard,
  Loader,
  Stack,
  Text
} from '@mantine/core'
import {
  IconCrosshair,
  IconEdit,
  IconLock,
  IconTrash
} from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { MediaFileName } from '@/components/datasets/MediaGallery/MediaFileName'
import { LabelGroup } from '@/components/labels/LabelGroup/LabelGroup'
import { FileMetadata } from '@/types/dataset'
import { Label } from '@/types/label'
import {
  AnnotationAuthor,
  AnnotationDate,
  AnnotationStatus,
  DisplayFps
} from './AnnotationInfo'

type ViewModeToolbarProps = {
  fileName: string
  fileType: FileMetadata['media_type']
  labelFps?: number
  originalFps?: number
  isLabeled?: boolean
  labelerEmail?: string
  labelList?: Label[]
  labeledDate?: string
  showMoreInfo: boolean
  isLoading: boolean
  isAnnotationDisabled?: boolean
  onStartLabeling: () => void
  onViewLabeling: () => void
  onRemoveLabeling: () => void
}

export const ViewModeToolbar = ({
  fileName,
  fileType,
  labelFps,
  originalFps,
  isLabeled,
  labelerEmail,
  labelList = [],
  labeledDate,
  showMoreInfo,
  isLoading,
  isAnnotationDisabled,
  onStartLabeling,
  onViewLabeling,
  onRemoveLabeling
}: ViewModeToolbarProps) => {
  const showFps = labelFps !== undefined && originalFps !== undefined

  return (
    <Group justify="space-between" align="center">
      <Group align="center" gap="lg">
        <MediaFileName fileName={fileName} fileType={fileType} />

        {isLabeled && (
          <>
            <AnnotationStatus />

            {showMoreInfo && (
              <HoverCard shadow="md" width={300} withArrow>
                <HoverCard.Target>
                  <Anchor underline="never" size="xs">
                    <FormattedMessage id="annotation.showInfo" />
                  </Anchor>
                </HoverCard.Target>

                <HoverCard.Dropdown>
                  <Stack gap="md">
                    <Text size="sm" fw="bold">
                      <FormattedMessage id="annotation.labeledBy" />
                    </Text>

                    <Stack gap="xs">
                      <AnnotationAuthor labelerEmail={labelerEmail || ''} />
                      <AnnotationDate date={labeledDate || ''} />
                    </Stack>

                    <LabelGroup labels={labelList} />

                    {showFps && (
                      <Card bg="blue.0" padding="xs">
                        <DisplayFps
                          labelFps={labelFps}
                          originalFps={originalFps}
                        />
                      </Card>
                    )}
                  </Stack>
                </HoverCard.Dropdown>
              </HoverCard>
            )}
          </>
        )}

        {isLoading && <Loader size={14} color="gray" />}
      </Group>

      {!isLabeled && (
        <Button
          miw={200}
          leftSection={
            isAnnotationDisabled ? (
              <IconLock size={20} />
            ) : (
              <IconCrosshair size={20} />
            )
          }
          disabled={isAnnotationDisabled}
          onClick={onStartLabeling}
        >
          <FormattedMessage id="annotation.labelFile" />
        </Button>
      )}

      {isLabeled && (
        <Group>
          <Button
            miw={200}
            leftSection={<IconEdit size={16} />}
            onClick={onViewLabeling}
          >
            <FormattedMessage id="annotation.view" />
          </Button>

          <Button
            miw={200}
            variant="outline"
            leftSection={
              isAnnotationDisabled ? (
                <IconLock size={16} />
              ) : (
                <IconTrash size={14} />
              )
            }
            disabled={isAnnotationDisabled}
            onClick={onRemoveLabeling}
          >
            <FormattedMessage id="annotation.remove" />
          </Button>
        </Group>
      )}
    </Group>
  )
}
