import { Notification } from '../types/notifications'
import { PaginatedResponse } from '../types/pagination'
import { vpApi } from './vpApi'

type NotificationsResponse = PaginatedResponse<Notification[]>

type GetNotificationsParams = {
  pageUrl: string
}

const getNotifications = async ({ pageUrl }: GetNotificationsParams) => {
  const res = await vpApi.get<NotificationsResponse>(pageUrl)
  return res.data
}

const markNotificationAsRead = (id: string) => {
  return vpApi.patch(`/v1/notifications/${id}/mark_read/`)
}

const markAllNotificationsAsRead = () => {
  return vpApi.patch('/v1/notifications/mark_all_read/')
}

export const notificationApi = {
  getNotifications,
  markNotificationAsRead,
  markAllNotificationsAsRead
}
