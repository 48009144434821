export type TimeRange = {
  from: string
  to: string
}

/**
 * Converts time string in "HH:mm" format to minutes since midnight
 */
export const parseTimeToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number)
  return hours * 60 + minutes
}

/**
 * Converts time string in "HH:mm" format to "HH:mm AM/PM" format
 */
export const convertTo12Hour = (time24: string): string => {
  const [hours, minutes] = time24.split(':')
  const hour = parseInt(hours, 10)
  const ampm = hour >= 12 ? 'PM' : 'AM'
  const hour12 = hour % 12 || 12
  return `${hour12}:${minutes} ${ampm}`
}
