import { Alert, Box, Stack, Tabs, Tooltip, rem } from '@mantine/core'
import { useSessionStorage } from '@mantine/hooks'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { ApplicationNestedPath, buildAppLink } from '@/router/paths'
import { Application, ApplicationType } from '@/types/app'
import { MLModelType } from '@/types/model'
import { PageWithFixedToolbar } from '../layout/PageWithFixedToolbar/PageWithFixedToolbar'
import { ModelList } from './ModelList/ModelList'
import { ModelListTab } from './ModelList/ModelListTab'
import { useModelListStatus } from './ModelList/useModelListStatus'
import { ModelListToolbar } from './ModelListToolbar/ModelListToolbar'

const STORAGE_KEY = 'model-list-active-tab'
const getStorageKey = (appId: string) => `${STORAGE_KEY}-${appId}`

type IsDeployDisabledParams = {
  primaryModelCount: number
  secondaryModelCount: number
  appType: ApplicationType
}

const isDeployDisabled = ({
  primaryModelCount,
  secondaryModelCount,
  appType
}: IsDeployDisabledParams) => {
  if (appType === ApplicationType.SingleStage) {
    return primaryModelCount === 0
  }

  if (appType === ApplicationType.MultiStage) {
    return primaryModelCount === 0 || secondaryModelCount === 0
  }

  return true
}

export const ModelsPage = () => {
  const navigate = useNavigate()

  const { application } = useApplicationContext()
  const { application_type } = application as Application

  const applicationId = application?.id || ''

  // Persist the active tab in session storage to maintain state when navigating back to the model list
  const [activeTab, setActiveTab] = useSessionStorage({
    key: getStorageKey(applicationId),
    defaultValue: String(MLModelType.Primary)
  })

  const { primaryModelCount, secondaryModelCount, isStatusLoading } =
    useModelListStatus(applicationId)

  const isSecondStageDisabled =
    primaryModelCount === 0 && secondaryModelCount === 0 && !isStatusLoading

  // Clear other model list tabs when switching between applications
  useEffect(() => {
    Object.keys(sessionStorage).forEach((key) => {
      if (key.startsWith(STORAGE_KEY) && key !== getStorageKey(applicationId)) {
        sessionStorage.removeItem(key)
      }
    })
  }, [applicationId])

  const handleOnDeploy = () => {
    void navigate(
      buildAppLink(applicationId, ApplicationNestedPath.createDeployment)
    )
  }

  return (
    <PageWithFixedToolbar
      toolbar={
        <ModelListToolbar
          isMultiStage={application_type === ApplicationType.MultiStage}
          isDeployDisabled={isDeployDisabled({
            primaryModelCount,
            secondaryModelCount,
            appType: application_type
          })}
          isLoading={isStatusLoading}
          onDeploy={handleOnDeploy}
        />
      }
    >
      {application_type === ApplicationType.SingleStage && (
        <Box pt="lg">
          <ModelList
            modelType={MLModelType.Primary}
            applicationId={applicationId}
            addModelLabel={<FormattedMessage id="models.list.new" />}
          />
        </Box>
      )}

      {application_type === ApplicationType.MultiStage && (
        <Tabs
          value={activeTab}
          variant="outline"
          radius="md"
          styles={{
            tab: {
              minWidth: rem(120)
            },
            tabLabel: {
              fontSize: rem(14),
              fontWeight: 600
            },
            panel: {
              paddingTop: rem(8)
            }
          }}
          onChange={(val) => setActiveTab(val as string)}
        >
          <Tabs.List>
            <ModelListTab
              value={String(MLModelType.Primary)}
              label={<FormattedMessage id="models.list.tabs.stage1" />}
              isCompleted={primaryModelCount > 0}
            />

            <ModelListTab
              value={String(MLModelType.Secondary)}
              label={
                <Tooltip
                  w={240}
                  disabled={primaryModelCount > 0 || secondaryModelCount > 0}
                  label={<FormattedMessage id="models.list.stage2.unlock" />}
                  position="bottom"
                  multiline
                >
                  <div>
                    <FormattedMessage id="models.list.tabs.stage2" />
                  </div>
                </Tooltip>
              }
              isCompleted={secondaryModelCount > 0}
              isDisabled={isSecondStageDisabled}
            />
          </Tabs.List>

          <Tabs.Panel value={String(MLModelType.Primary)}>
            <Stack pt="lg" gap="lg">
              {isSecondStageDisabled && (
                <Alert color="blue">
                  <FormattedMessage id="models.list.multiStageInfo" />
                </Alert>
              )}

              <ModelList
                modelType={MLModelType.Primary}
                applicationId={applicationId}
                addModelLabel={<FormattedMessage id="models.list.new.stage1" />}
              />
            </Stack>
          </Tabs.Panel>

          <Tabs.Panel value={String(MLModelType.Secondary)}>
            <Box pt="lg">
              <ModelList
                modelType={MLModelType.Secondary}
                applicationId={applicationId}
                addModelLabel={<FormattedMessage id="models.list.new.stage2" />}
              />
            </Box>
          </Tabs.Panel>
        </Tabs>
      )}
    </PageWithFixedToolbar>
  )
}
