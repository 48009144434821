import { Button, Group, ScrollArea, Stack, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'
import { EmailMultiselect } from './EmailMultiselect/EmailMultiselect'
import { ScheduleForm, ScheduleFormValues } from './Schedule/ScheduleForm'
import { ScheduleHeader } from './Schedule/ScheduleHeader'
import { Schedule, ScheduleItem } from './Schedule/ScheduleItem/ScheduleItem'
import { ScheduleModal } from './Schedule/ScheduleModal'
import { useSchedule } from './Schedule/useSchedule'

export type ScheduleToggleConfig = {
  uuid: string
  schedule: Schedule
  isEnabled: boolean
}

export type NotificationSettingsFormValues = {
  emails: string[]
  schedules: ScheduleToggleConfig[]
}

type NotificationSettingsFormProps = {
  onCancel: () => void
  onSave: (schedules: ScheduleToggleConfig[], emails: string[]) => void
}

export const NotificationSettingsForm = ({
  onCancel,
  onSave
}: NotificationSettingsFormProps) => {
  const intl = useIntl()
  const [
    isAddScheduleModalOpened,
    { open: openAddScheduleModal, close: closeAddScheduleModal }
  ] = useDisclosure()

  const [selectedSchedule, setSelectedSchedule] = useState<{
    index: number
    schedule: Schedule
  } | null>(null)

  const form = useForm<NotificationSettingsFormValues>({
    initialValues: {
      emails: [],
      schedules: [
        {
          uuid: uuidv4(),
          schedule: {
            timeRange: {
              from: '10:00',
              to: '11:00'
            },
            selectedDays: [6, 7]
          },
          isEnabled: true
        }
      ]
    },
    validate: {
      emails: (value, values) => {
        if (values.schedules.length > 0 && value.length === 0) {
          return intl.formatMessage({
            id: 'logic.notificationSettings.validation.emailsRequired'
          })
        }
        return null
      },
      schedules: (value, values) => {
        if (values.emails.length > 0 && value.length === 0) {
          return intl.formatMessage({
            id: 'logic.notificationSettings.validation.schedulesRequired'
          })
        }
        return null
      }
    }
  })

  const handleCloseScheduleModal = () => {
    setSelectedSchedule(null)
    closeAddScheduleModal()
  }

  const handleUpdateClick = (index: number) => {
    const schedule = form.values.schedules[index]
    if (schedule) {
      setSelectedSchedule({ index: index, schedule: schedule.schedule })
      openAddScheduleModal()
    }
  }

  const handleSubmit = (values: NotificationSettingsFormValues) => {
    onSave(values.schedules, values.emails)
    onCancel()
  }

  const handleScheduleSave = (val: ScheduleFormValues) => {
    addSchedule(val)
    handleCloseScheduleModal()
  }

  const { addSchedule, toggleSchedule, removeSchedule } = useSchedule({
    form,
    selectedSchedule,
    closeScheduleModal: handleCloseScheduleModal
  })

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack gap="sm">
          <EmailMultiselect
            label={intl.formatMessage({
              id: 'logic.notificationSettings.email.label'
            })}
            value={form.values.emails}
            error={form.errors.emails as string | undefined}
            onChange={(emails) => form.setFieldValue('emails', emails)}
          />

          <ScheduleHeader onAddClick={openAddScheduleModal} />

          <ScrollArea.Autosize mah={300} scrollbarSize={8}>
            <Stack gap="xs">
              {form.values.schedules.map((schedule, index) => (
                <ScheduleItem
                  key={schedule.uuid}
                  item={schedule}
                  onToggle={() => toggleSchedule(index)}
                  onRemove={() => removeSchedule(index)}
                  onUpdateClick={() => handleUpdateClick(index)}
                />
              ))}
            </Stack>
          </ScrollArea.Autosize>
          {form.errors.schedules && (
            <Text c="red" size="sm">
              {form.errors.schedules}
            </Text>
          )}

          <Group justify="end" mt="md">
            <Button miw={120} variant="default" onClick={onCancel}>
              <FormattedMessage id="cancel" />
            </Button>
            <Button miw={120} type="submit">
              <FormattedMessage id="save" />
            </Button>
          </Group>
        </Stack>
      </form>

      <ScheduleModal
        opened={isAddScheduleModalOpened}
        onClose={handleCloseScheduleModal}
      >
        <ScheduleForm
          schedule={selectedSchedule?.schedule}
          onClose={handleCloseScheduleModal}
          onSave={handleScheduleSave}
        />
      </ScheduleModal>
    </>
  )
}
