import { ReactNode, createContext, useContext } from 'react'
import { ApplicationType } from '@/types/app'
import { Label } from '@/types/label'
import { MLModel } from '@/types/model'

type ContextProps = {
  appType: ApplicationType
  primaryModel: MLModel | null
  secondaryModel: MLModel | null
  primaryLabels: Label[]
  secondaryLabels: Label[]
}

const initialValues = {
  appType: ApplicationType.SingleStage,
  primaryModel: null,
  secondaryModel: null,
  primaryLabels: [],
  secondaryLabels: []
}

const DeploymentStepperContext = createContext<ContextProps>(initialValues)

type DeploymentStepperProviderProps = {
  appType: ApplicationType
  primaryModel: MLModel | null
  secondaryModel: MLModel | null
  children: ReactNode
}

export const DeploymentStepperProvider = ({
  appType,
  primaryModel,
  secondaryModel,
  children
}: DeploymentStepperProviderProps) => {
  const primaryLabels =
    primaryModel?.dataset_version?.labels ||
    primaryModel?.base_model?.dataset_version?.labels ||
    []

  const secondaryLabels =
    secondaryModel?.dataset_version?.labels ||
    secondaryModel?.base_model?.dataset_version?.labels ||
    []

  return (
    <DeploymentStepperContext.Provider
      value={{
        appType,
        primaryModel,
        secondaryModel,
        primaryLabels,
        secondaryLabels
      }}
    >
      {children}
    </DeploymentStepperContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useDeploymentStepperContext = () => {
  return useContext(DeploymentStepperContext)
}
