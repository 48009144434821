import { Alert, Button, Card, Group, Text, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import {
  IconChevronLeft,
  IconChevronRight,
  IconInfoCircle
} from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StepSection } from '@/components/deployments/deployment-editor/StepSection'
import {
  DisplayConfidenceThreshold,
  EventSettingsPanel
} from '@/components/events/EventSettingsPanel/EventSettingsPanel'
import { IntegrationInputHandler } from '@/components/integrations/IntegrationInputHandler/IntegrationInputHandler'
import { IntegrationListItem } from '@/components/integrations/IntegrationListItem/IntegrationListItem'
import { ConfidenceThreshold, EventDestination } from '@/types/deployment'
import { isValidUrl } from '@/utils/validation/url'
import { useDeploymentStepperContext } from '../DeploymentStepperContext'
import { ConfigValues, integrationConfig } from './config'

export type SavedEventEndpoint = {
  service: EventDestination
  endpoint?: string
}

type DisplayIntegration = {
  name: string
  description: string
  logo: string
  type: EventDestination
  endpointUrl: string
  endpointUrlRequired: boolean
  isSelected: boolean
}

type IntegrationSelectionProps = {
  selectedDeviceIds: string[]
  savedEventEndpoints: SavedEventEndpoint[]
  confidenceThresholds: ConfidenceThreshold[]
  onGoBack: () => void
  onContinue: (endpoints: SavedEventEndpoint[]) => void
  onConfidenceChange: (confidenceThresholds: ConfidenceThreshold[]) => void
}

export const IntegrationSelection = ({
  selectedDeviceIds,
  savedEventEndpoints,
  confidenceThresholds,
  onGoBack,
  onContinue,
  onConfidenceChange
}: IntegrationSelectionProps) => {
  const intl = useIntl()

  const form = useForm<{ integrations: DisplayIntegration[] }>({
    initialValues: {
      integrations: (
        Object.entries(integrationConfig) as [EventDestination, ConfigValues][]
      ).map(([type, config]) => {
        const savedEndpoint = savedEventEndpoints.find(
          (endpoint) => endpoint.service === type
        )

        return {
          name: config.name,
          description: config.description,
          logo: config.logo,
          type: type,
          endpointUrl: savedEndpoint?.endpoint || '',
          endpointUrlRequired: type !== EventDestination.Visionplatform,
          isSelected: savedEndpoint !== undefined
        }
      })
    },

    validateInputOnChange: true,

    validate: {
      integrations: {
        endpointUrl: (value, formValues, path) => {
          const index = Number(path.split('.')[1])
          const integration = formValues.integrations[index]

          if (
            integration.isSelected &&
            integration.endpointUrlRequired &&
            !isValidUrl(value)
          ) {
            return intl.formatMessage({
              id: 'validation.url.invalid'
            })
          }

          return null
        }
      }
    }
  })

  const { primaryLabels, secondaryLabels } = useDeploymentStepperContext()

  const confidenceThresholdOptions = confidenceThresholds.map(
    (thresholdObj) => ({
      labelId: thresholdObj.label_id,
      labelName:
        primaryLabels
          .concat(secondaryLabels)
          .find((label) => label.id === thresholdObj.label_id)?.name || '',
      threshold: thresholdObj.threshold
    })
  )

  const handleSelectionChange = (index: number, value: boolean) => {
    form.setFieldValue(`integrations.${index}.isSelected`, value)
    form.clearErrors()
  }

  const handleContinueClick = () => {
    const selectedIntegrations = form.values.integrations.filter(
      (integration) => integration.isSelected
    )

    const savedEventEndpoints = selectedIntegrations.map((integration) => {
      return {
        service: integration.type,
        endpoint: integration.endpointUrl
      }
    })

    onContinue(savedEventEndpoints)
  }

  const handleConfidenceChange = (thresholdObj: DisplayConfidenceThreshold) => {
    const updatedThresholds = confidenceThresholds.map((ct) =>
      ct.label_id === thresholdObj.labelId
        ? { ...ct, threshold: thresholdObj.threshold }
        : ct
    )

    onConfidenceChange(updatedThresholds)
  }

  return (
    <>
      <Title order={3} mb="md">
        <FormattedMessage id="deployments.step6" />
      </Title>

      <StepSection
        actions={
          <Group>
            <Button
              size="md"
              variant="outline"
              miw={200}
              leftSection={<IconChevronLeft size={16} stroke={3} />}
              onClick={onGoBack}
            >
              <FormattedMessage id="deployments.previousStep" />
            </Button>

            <Button
              miw={200}
              size="md"
              rightSection={<IconChevronRight size={16} stroke={3} />}
              disabled={!form.isValid()}
              onClick={handleContinueClick}
            >
              <FormattedMessage id="deployments.nextStep" />
            </Button>
          </Group>
        }
      >
        <Card p="lg">
          <Card.Section py="md" inheritPadding>
            <Text size="sm">
              <FormattedMessage id="integrations.notificationPreference" />
            </Text>
          </Card.Section>

          {form.values.integrations.map((integration, index) => (
            <Card.Section
              key={integration.type}
              py="xl"
              inheritPadding
              withBorder
            >
              <IntegrationListItem
                isSelected={integration.isSelected}
                title={<FormattedMessage id={integration.name} />}
                description={<FormattedMessage id={integration.description} />}
                logoSrc={integration.logo}
                showDetails={integration.isSelected}
                onChange={(value) => handleSelectionChange(index, value)}
              >
                {integration.type === EventDestination.CustomApi && (
                  <IntegrationInputHandler
                    {...form.getInputProps(`integrations.${index}.endpointUrl`)}
                    label={<FormattedMessage id="integrations.endpointUrl" />}
                    placeholder="https://api.your-domain.com/events"
                    deviceId={selectedDeviceIds[0]} // TODO we have a task to validate URL for multiple devices
                    source={EventDestination.CustomApi}
                    isTestButtonDisabled={
                      !form.isValid(`integrations.${index}.endpointUrl`)
                    }
                  />
                )}

                {integration.type === EventDestination.Milestone && (
                  <>
                    <Alert
                      variant="light"
                      color="blue"
                      icon={<IconInfoCircle />}
                    >
                      <Text size="xs">
                        <FormattedMessage
                          id="integrations.milestone.info"
                          values={{
                            b: (chunks) => <b>{chunks}</b>
                          }}
                        />
                      </Text>
                    </Alert>

                    <IntegrationInputHandler
                      {...form.getInputProps(
                        `integrations.${index}.endpointUrl`
                      )}
                      label={
                        <FormattedMessage id="integrations.milestone.inputLabel" />
                      }
                      description={
                        <FormattedMessage
                          id="integrations.milestone.inputHint"
                          values={{
                            url: 'https://<aibridge host>:<aibridge port>/api/bridge/graphql'
                          }}
                        />
                      }
                      inputWrapperOrder={[
                        'label',
                        'input',
                        'description',
                        'error'
                      ]}
                      deviceId={selectedDeviceIds[0]} // TODO we have a task to validate URL for multiple devices
                      source={EventDestination.Milestone}
                      isTestButtonDisabled={
                        !form.isValid(`integrations.${index}.endpointUrl`)
                      }
                    />
                  </>
                )}
              </IntegrationListItem>
            </Card.Section>
          ))}
        </Card>

        <Card mt="xl" padding="xl">
          <EventSettingsPanel
            confidenceThresholds={confidenceThresholdOptions}
            onConfidenceChange={handleConfidenceChange}
          />
        </Card>
      </StepSection>
    </>
  )
}
