import { Badge, Table } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

type DetectionInfoProps = {
  detectionName: string
  confidence?: number
  dwellTime?: number
}

export const DetectionInfo = ({
  detectionName,
  confidence,
  dwellTime
}: DetectionInfoProps) => {
  return (
    <Table horizontalSpacing="md" verticalSpacing="sm">
      <Table.Tbody>
        <Table.Tr>
          <Table.Td width="40%">
            <strong>
              <FormattedMessage id="events.object" />
            </strong>
          </Table.Td>

          <Table.Td>
            <Badge>{detectionName}</Badge>
          </Table.Td>
        </Table.Tr>

        {confidence !== undefined && (
          <Table.Tr>
            <Table.Td>
              <strong>
                <FormattedMessage id="events.confidence" />
              </strong>
            </Table.Td>

            <Table.Td>{confidence.toFixed(2)}</Table.Td>
          </Table.Tr>
        )}

        {dwellTime !== undefined && (
          <Table.Tr>
            <Table.Td>
              <strong>
                <FormattedMessage id="events.dwellTime" />
              </strong>
            </Table.Td>

            <Table.Td>
              <FormattedMessage
                id="events.dwellTime.seconds"
                values={{ value: dwellTime.toFixed(2) }}
              />
            </Table.Td>
          </Table.Tr>
        )}
      </Table.Tbody>
    </Table>
  )
}
