import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { LoginPayload, accountApi } from '@/api/accountApi'
import { setCsrfToken } from '@/api/vpApi'
import { RouterPath } from '@/router/paths'
import { useSession } from '@/session/SessionContext'
import { getWhoAmIandMySubscription } from './helpers'

type LocationState = {
  redirectTo: string
} | null

export const useLogin = () => {
  const { setUser, setSubscriptions } = useSession()
  const navigate = useNavigate()
  const location = useLocation()
  const [isPending, setIsPending] = useState(false)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState<unknown>(null)

  const state = location.state as LocationState

  const login = async (payload: LoginPayload) => {
    setError(null)
    setIsError(false)
    setIsPending(true)

    try {
      await accountApi.login(payload)

      // new csrf token must be retrieved after login
      const csrfToken = await accountApi.getCsrfToken()
      setCsrfToken(csrfToken)

      const { user, subscriptions } = await getWhoAmIandMySubscription()

      setUser(user)
      setSubscriptions(subscriptions)

      if (state?.redirectTo) {
        void navigate(state?.redirectTo)
      } else {
        void navigate(RouterPath.root)
      }
    } catch (err) {
      setError(err)
      setIsError(true)
    }
    setIsPending(false)
  }

  return {
    login,
    isPending,
    isError,
    error
  }
}
