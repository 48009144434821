import MilestoneLogo from '@/assets/integrations/milestone.png'
import RestApiLogo from '@/assets/integrations/rest-api.png'
import VpLogo from '@/assets/vp-stripes.png'
import { EventDestination } from '@/types/deployment'

export type ConfigValues = {
  name: string
  description: string
  logo: string
}

export const integrationConfig: Record<EventDestination, ConfigValues> = {
  [EventDestination.Visionplatform]: {
    name: 'integrations.website.title',
    description: 'integrations.website.description',
    logo: VpLogo
  },
  [EventDestination.CustomApi]: {
    name: 'integrations.customApi.title',
    description: 'integrations.customApi.description',
    logo: RestApiLogo
  },
  [EventDestination.Milestone]: {
    name: 'integrations.milestone.title',
    description: 'integrations.milestone.description',
    logo: MilestoneLogo
  }
}
