import {
  Button,
  Card,
  Collapse,
  Group,
  Loader,
  SimpleGrid,
  Text
} from '@mantine/core'
import { FileRejection } from '@mantine/dropzone'
import { useDisclosure, useHotkeys, useViewportSize } from '@mantine/hooks'
import { IconUpload } from '@tabler/icons-react'
import { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { InfiniteScroll } from '@/components/ui-shared/InfiniteScroll/InfiniteScroll'
import {
  useGetDatasetVersionFiles,
  useInvalidateFileList
} from '@/queries/datasetQueries'
import { BORDERED_CARD_STYLES } from '@/theme/customStyles'
import { FileStatus } from '@/types/dataset'
import { DeleteFilesModal } from '../DeleteFilesModal/DeleteFilesModal'
import { FileSelectionBar } from '../FileSelectionBar/FileSelectionBar'
import { useFileSelection } from '../FileSelectionBar/useFileSelection'
import { MediaCard } from '../MediaCard/MediaCard'
import { MediaViewHandler } from '../MediaViewHandler/MediaViewHandler'
import { UploadDropzone } from '../UploadDropzone/UploadDropzone'

type UploadableMediaListProps = {
  versionId: string
  isLocked: boolean
  isUploading: boolean
  onDrop: (files: File[]) => void
  onReject: (files: FileRejection[]) => void
  onShowUploadProgress: () => void
}

export const UploadableMediaList = ({
  versionId,
  isLocked,
  isUploading,
  onDrop,
  onReject,
  onShowUploadProgress
}: UploadableMediaListProps) => {
  const openRef = useRef<() => void>(null)

  const [activeFileIndex, setActiveFileIndex] = useState(0)

  const [
    isMediaGalleryOpened,
    { open: openMediaGallery, close: closeMediaGallery }
  ] = useDisclosure(false)

  const [
    isDeleteModalOpened,
    { open: openDeleteModal, close: closeDeleteModal }
  ] = useDisclosure(false)

  const { invalidateFileList } = useInvalidateFileList(versionId)

  const { data, isFetching, isError, refetch, fetchNextPage, hasNextPage } =
    useGetDatasetVersionFiles({
      versionId
    })

  const files = data?.pages?.flatMap((page) => page.results) || []

  const totalFileCount = data?.pages?.[0]?.count || 0

  const { height } = useViewportSize()

  const {
    selectedFileIds,
    isSelectionModeEnabled,
    toggleFileSelection,
    clearFileSelection,
    selectFiles
  } = useFileSelection()

  useHotkeys([['Escape', clearFileSelection]])

  useHotkeys([
    [
      'mod+A',
      () => {
        if (isSelectionModeEnabled) {
          selectFiles(files.map((file) => file.id))
        }
      }
    ]
  ])

  const handleSelectionChange = (selected: boolean) => {
    if (selected) {
      selectFiles(files.map((file) => file.id))
    } else {
      clearFileSelection()
    }
  }

  const handleUploadClick = () => {
    if (isUploading) {
      onShowUploadProgress()
    } else {
      openRef.current?.()
    }
  }

  const handleFileDelete = () => {
    closeDeleteModal()
    clearFileSelection()
    invalidateFileList()
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={() => void refetch()}>
        <FormattedMessage id="datasets.data.error" />
      </ErrorWithReload>
    )
  }

  return (
    <>
      <Collapse in={isSelectionModeEnabled}>
        <Card styles={BORDERED_CARD_STYLES} mb="md" withBorder>
          <FileSelectionBar
            allFilesSelected={files.length === selectedFileIds.length}
            fileCount={selectedFileIds.length}
            onSelectionChange={handleSelectionChange}
            onDeleteFiles={openDeleteModal}
          />
        </Card>
      </Collapse>

      {(files.length > 0 || (files.length === 0 && isUploading)) &&
        !isSelectionModeEnabled && (
          <Group justify="space-between" align="end" mb="xs">
            <Button
              variant="subtle"
              size="xs"
              radius="xl"
              miw={140}
              leftSection={
                isUploading ? <Loader size={16} /> : <IconUpload size={16} />
              }
              disabled={isLocked}
              onClick={handleUploadClick}
            >
              <FormattedMessage id="datasets.upload.uploadFiles" />
            </Button>

            {totalFileCount > 0 && (
              <Text size="xs" fw={600}>
                (
                <FormattedMessage
                  id="datasets.fileCount"
                  values={{ count: totalFileCount }}
                />
                )
              </Text>
            )}
          </Group>
        )}

      <UploadDropzone
        openRef={openRef}
        activateOnClick={files.length === 0 && !isFetching}
        showInstructions={files.length === 0 && !isFetching}
        minHeight={height - 300}
        disabled={isLocked}
        withDisabledStyle={false}
        onDrop={onDrop}
        onReject={onReject}
      >
        <InfiniteScroll
          hasMore={hasNextPage}
          isFetching={isFetching}
          onLoadMore={() => void fetchNextPage()}
        >
          <SimpleGrid cols={{ base: 1, sm: 2, lg: 4, xl: 7 }} spacing="xl">
            {files.map((file, index) => (
              <MediaCard
                key={file.id}
                fileName={file.name}
                thumbnailUrl={file.thumbnail}
                isSelected={selectedFileIds.includes(file.id)}
                isSelectionMode={isSelectionModeEnabled}
                isLabeled={file.status === FileStatus.Labeled}
                isLocked={isLocked}
                isSelectable
                onClick={() => {
                  setActiveFileIndex(index)
                  openMediaGallery()
                }}
                onSelectionChange={() => toggleFileSelection(file.id)}
              />
            ))}
          </SimpleGrid>
        </InfiniteScroll>
      </UploadDropzone>

      <MediaViewHandler
        opened={isMediaGalleryOpened}
        activeFileIndex={activeFileIndex}
        files={files}
        datasetVersionId={versionId}
        isAnnotationDisabled={isLocked}
        isMediaLoading={isFetching}
        onClose={closeMediaGallery}
        onActiveFileChange={setActiveFileIndex}
      />

      <DeleteFilesModal
        opened={isDeleteModalOpened}
        versionId={versionId}
        selectedFileIds={selectedFileIds}
        onDelete={handleFileDelete}
        onClose={closeDeleteModal}
      />
    </>
  )
}
