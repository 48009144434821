import { Checkbox, Grid, Group, Image, Stack, Text } from '@mantine/core'
import { ReactNode } from 'react'

type IntegrationListItemProps = {
  isSelected: boolean
  logoSrc?: string
  title: string | ReactNode
  description?: string | ReactNode
  showDetails?: boolean
  children?: ReactNode
  onChange: (value: boolean) => void
}

export const IntegrationListItem = ({
  isSelected,
  logoSrc,
  title,
  description,
  showDetails = false,
  children,
  onChange
}: IntegrationListItemProps) => {
  return (
    <Grid gutter="lg">
      <Grid.Col span="content">
        <Checkbox
          checked={isSelected}
          onChange={(event) => onChange(event.currentTarget.checked)}
        />
      </Grid.Col>

      <Grid.Col span="auto">
        <Stack gap={4} pos="relative" top={-4}>
          <Group align="center" wrap="nowrap" gap="md">
            {logoSrc && <Image src={logoSrc} w={28} h={28} fit="contain" />}

            <Text size="sm" fw="bold">
              {title}
            </Text>
          </Group>

          <Stack ml={45} maw={600} gap="xl">
            {description && (
              <Text size="xs" c="dimmed">
                {description}
              </Text>
            )}

            {showDetails && children}
          </Stack>
        </Stack>
      </Grid.Col>
    </Grid>
  )
}
