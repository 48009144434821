import { useCallback } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { ApplicationNestedPath, buildAppLink } from '@/router/paths'

export const STEP_QUERY_PARAM = 'step'

export const useDeploymentNavigation = () => {
  const navigate = useNavigate()

  const navigateToDeploymentStep = useCallback(
    (appId: string, step: number) => {
      const queryParams = createSearchParams({
        [STEP_QUERY_PARAM]: step.toString()
      }).toString()

      void navigate({
        pathname: buildAppLink(appId, ApplicationNestedPath.createDeployment),
        search: queryParams
      })
    },
    [navigate]
  )

  return { navigateToDeploymentStep }
}
