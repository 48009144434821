import { Button, Group, Stack, Text } from '@mantine/core'
import { IconCalendarClock } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

type ScheduleHeaderProps = {
  onAddClick: () => void
}

export const ScheduleHeader = ({ onAddClick }: ScheduleHeaderProps) => (
  <Group gap="xs" mt="sm" justify="space-between">
    <Stack gap={0}>
      <Text size="sm" fw={700}>
        <FormattedMessage id="logic.notificationSettings.schedule" />
      </Text>
      <Text size="xs">
        <FormattedMessage id="logic.notificationSettings.schedule.description" />
      </Text>
    </Stack>

    <Button
      size="xs"
      radius="xl"
      leftSection={<IconCalendarClock size={16} />}
      onClick={onAddClick}
    >
      <FormattedMessage id="logic.notificationSettings.schedule.add" />
    </Button>
  </Group>
)
