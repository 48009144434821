import {
  Box,
  Card,
  Center,
  CloseButton,
  Collapse,
  Grid,
  Group,
  Tooltip
} from '@mantine/core'
import { FormErrors } from '@mantine/form'
import { FormattedMessage } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'
import { ApplicationType } from '@/types/app'
import {
  BusinessLogicRuleOperator,
  LabelGroupType,
  LibraryLogicType,
  NormalizedBusinessLogicParameter,
  NormalizedBusinessLogicRule,
  NormalizedLabelGroup,
  ParameterType,
  SubLabelOperator
} from '@/types/businessLogic'
import { useDeploymentStepperContext } from '../../DeploymentStepperContext'
import {
  AnnotationArea,
  AnnotationValues
} from '../AnnotationArea/AnnotationArea'
import { EventSettingType, EventSettings } from '../EventSettings/EventSettings'
import { RuleBuilder } from '../RuleBuilder/RuleBuilder'
import { getFieldErrors } from '../helpers/getFieldErrors'
import { useCameraImage } from '../hooks/useCameraImage'
import { EmptyLogicText } from './EmptyLogicText'
import { ParameterHeader } from './LogicParameters/ParameterHeader'
import {
  DEFAULT_COUNT_THRESHOLD,
  DEFAULT_EVENT_SETTINGS,
  FRAME_HEIGHT,
  FRAME_WIDTH
} from './constants'

type StreamLogicConfiguratorProps = {
  active: boolean
  deviceId: string
  cameraStreamId: string
  logicType: LibraryLogicType
  enabledLogicParameters: ParameterType[]
  parameters: NormalizedBusinessLogicParameter[]
  streamErrors: FormErrors
  onParameterAdd: (parameter: NormalizedBusinessLogicParameter) => void
  onParameterChange: (
    index: number,
    parameter: NormalizedBusinessLogicParameter
  ) => void
  onParameterRemove: (index: number) => void
}

export const StreamLogicConfigurator = ({
  active,
  deviceId,
  cameraStreamId,
  logicType,
  enabledLogicParameters,
  parameters,
  streamErrors,
  onParameterAdd,
  onParameterChange,
  onParameterRemove
}: StreamLogicConfiguratorProps) => {
  const { isImageLoading, cameraImage, refreshCameraImage } = useCameraImage({
    deviceId,
    cameraStreamId,
    enabled: active
  })

  const {
    appType,
    primaryModel,
    secondaryModel,
    primaryLabels,
    secondaryLabels
  } = useDeploymentStepperContext()

  const labelOptions = [
    {
      group: primaryModel?.name || '',
      items: primaryLabels
        .map((label) => ({
          value: label.id,
          label: label.name,
          color: label.color
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    },

    ...(appType === ApplicationType.MultiStage
      ? [
          {
            group: secondaryModel?.name || '',
            items: secondaryLabels
              // Remove duplicate labels
              .filter(
                (secondaryLabel) =>
                  !primaryLabels.some(
                    (primaryLabel) => primaryLabel.id === secondaryLabel.id
                  )
              )
              .map((label) => ({
                value: label.id,
                label: label.name,
                color: label.color
              }))
              .sort((a, b) => a.label.localeCompare(b.label))
          }
        ]
      : [])
  ]

  const handleAnnotationAdd = (annotation: AnnotationValues) => {
    onParameterAdd({
      ...annotation,
      ...DEFAULT_EVENT_SETTINGS,
      rules: [
        {
          operator: BusinessLogicRuleOperator.OR,
          label_groups: []
        }
      ]
    })
  }

  const handleAnnotationChange = (
    index: number,
    annotation: AnnotationValues
  ) => {
    onParameterChange(index, {
      ...parameters[index],
      ...annotation
    })
  }

  const handleLabelGroupAdd = (parameterIndex: number) => {
    const updatedLabelGroups = [
      ...parameters[parameterIndex].rules[0].label_groups,
      {
        uuid: uuidv4(),
        type: LabelGroupType.Single,
        label_id: '',
        sub_label_ids: [],
        sub_label_operator: SubLabelOperator.With,
        threshold: DEFAULT_COUNT_THRESHOLD,
        min_dwell_time: null,
        alert_on_exit: false
      }
    ]
    onParameterChange(parameterIndex, {
      ...parameters[parameterIndex],
      rules: [
        {
          ...parameters[parameterIndex].rules[0],
          label_groups: updatedLabelGroups
        }
      ]
    })
  }

  const handleLabelGroupRemove = (
    parameterIndex: number,
    labelGroupIndex: number
  ) => {
    const updatedLabelGroups = parameters[
      parameterIndex
    ].rules[0].label_groups.filter((_, index) => index !== labelGroupIndex)

    onParameterChange(parameterIndex, {
      ...parameters[parameterIndex],
      rules: [
        {
          ...parameters[parameterIndex].rules[0],
          label_groups: updatedLabelGroups
        }
      ]
    })
  }

  const handleLabelGroupChange = (
    parameterIndex: number,
    labelGroupIndex: number,
    labelGroup: NormalizedLabelGroup
  ) => {
    const updatedLabelGroups = parameters[
      parameterIndex
    ].rules[0].label_groups.map((c, index) =>
      index === labelGroupIndex ? labelGroup : c
    )
    onParameterChange(parameterIndex, {
      ...parameters[parameterIndex],
      rules: [
        {
          ...parameters[parameterIndex].rules[0],
          label_groups: updatedLabelGroups
        }
      ]
    })
  }

  const handleOperatorChange = (
    value: NormalizedBusinessLogicRule['operator'],
    parameterIndex: number
  ) => {
    onParameterChange(parameterIndex, {
      ...parameters[parameterIndex],
      rules: [
        {
          ...parameters[parameterIndex].rules[0],
          operator: value
        }
      ]
    })
  }

  const handleEventSettingsChange = (
    settingType: EventSettingType,
    value: boolean,
    parameterIndex: number
  ) => {
    onParameterChange(parameterIndex, {
      ...parameters[parameterIndex],
      [settingType]: value
    })
  }

  const handleImageRefresh = () => {
    if (!isImageLoading) {
      void refreshCameraImage()
    }
  }

  return (
    <Grid>
      <Grid.Col span="content">
        <AnnotationArea
          enabledLogicParameters={enabledLogicParameters}
          imageUrl={cameraImage}
          isImageLoading={isImageLoading}
          width={FRAME_WIDTH}
          height={FRAME_HEIGHT}
          annotations={parameters.map((param) => ({
            type: param.type,
            name: param.name,
            color: param.color,
            coordinates: param.coordinates
          }))}
          onAnnotationAdd={handleAnnotationAdd}
          onAnnotationChange={handleAnnotationChange}
          onImageRefresh={() => void handleImageRefresh()}
        />
      </Grid.Col>

      <Grid.Col span="auto">
        <Box h="100%" maw={FRAME_WIDTH}>
          {parameters.length === 0 && (
            <Card h="100%" p="lg" radius={0} withBorder>
              <Center h="100%">
                <EmptyLogicText />
              </Center>
            </Card>
          )}

          {parameters.map((parameter, parameterIndex) => (
            <Card key={parameter.name} mb="md" bg="gray.0" withBorder>
              <Card.Section
                py="sm"
                withBorder={parameter.rules[0].label_groups.length > 0}
                inheritPadding
              >
                <Group justify="space-between" mb="md">
                  <ParameterHeader
                    parameterType={parameter.type}
                    name={parameter.name}
                    color={parameter.color}
                  />

                  <Tooltip label={<FormattedMessage id="remove" />}>
                    <CloseButton
                      onClick={() => onParameterRemove(parameterIndex)}
                    />
                  </Tooltip>
                </Group>

                <RuleBuilder
                  logicType={logicType}
                  logicParameterType={parameter.type}
                  labelGroups={parameter.rules[0].label_groups}
                  labelOptions={labelOptions}
                  ruleOperator={parameter.rules[0].operator}
                  errors={getFieldErrors(
                    streamErrors,
                    'parameters',
                    parameterIndex
                  )}
                  onLabelGroupAdd={() => handleLabelGroupAdd(parameterIndex)}
                  onLabelGroupRemove={(labelGroupIndex) => {
                    handleLabelGroupRemove(parameterIndex, labelGroupIndex)
                  }}
                  onLabelGroupChange={(labelGroupIndex, labelGroup) => {
                    handleLabelGroupChange(
                      parameterIndex,
                      labelGroupIndex,
                      labelGroup
                    )
                  }}
                  onOperatorChange={(value) => {
                    handleOperatorChange(value, parameterIndex)
                  }}
                />
              </Card.Section>

              <Collapse in={parameter.rules[0].label_groups.length > 0}>
                <Box pt="sm">
                  <EventSettings
                    notification={parameter.notification}
                    videoUpload={parameter.video_upload}
                    showNotification
                    showVideoUpload
                    onChange={(settingType, value) =>
                      handleEventSettingsChange(
                        settingType,
                        value,
                        parameterIndex
                      )
                    }
                  />
                </Box>
              </Collapse>
            </Card>
          ))}
        </Box>
      </Grid.Col>
    </Grid>
  )
}
