import {
  ActionIcon,
  Badge,
  Button,
  Center,
  Group,
  Menu,
  ScrollArea
} from '@mantine/core'
import { IconBellRinging } from '@tabler/icons-react'
import { useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { showToast } from '@/theme/notifications'
import { Notification } from '@/types/notifications'
import {
  useGetNotifications,
  useMarkAllNotificationsAsRead,
  useMarkNotificationAsRead
} from '../../queries/notificationQueries'
import { NotificationItem } from './NotificationItem'
import { getNotificationLink } from './getNotificationLink'

export const NotificationDropdown = () => {
  const navigate = useNavigate()
  const intl = useIntl()

  const scrollRef = useRef<HTMLDivElement>(null)
  const [opened, setOpened] = useState(false)

  const { mutateAsync: markOneAsReadAsync } = useMarkNotificationAsRead()
  const { mutateAsync: markAllAsReadAsync } = useMarkAllNotificationsAsRead()

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
    useGetNotifications()

  const notifications = data?.pages.flatMap((page) => page.results) ?? []

  const unreadCount = notifications.filter(
    (notification) => !notification.read_at
  ).length

  const scrollToBottom = () =>
    scrollRef.current!.scrollTo({
      top: scrollRef.current!.scrollHeight,
      behavior: 'smooth'
    })

  const handleMenuOpen = () => {
    setOpened(true)
    void refetch()
  }

  const handleFetchNextPage = async () => {
    await fetchNextPage()
    setTimeout(scrollToBottom, 200)
  }

  const markOneAsRead = async (id: string) => {
    try {
      await markOneAsReadAsync(id)
      void refetch()
    } catch {
      showToast(
        intl.formatMessage({ id: 'notifications.errorMarkRead' }),
        'error'
      )
    }
  }

  const markAllAsRead = async () => {
    try {
      await markAllAsReadAsync()
      void refetch()
    } catch {
      showToast(
        intl.formatMessage({ id: 'notifications.errorMarkRead' }),
        'error'
      )
    }
  }

  const handleNotificationClick = (notification: Notification) => {
    const notificationLink = getNotificationLink(
      notification.notification_type,
      notification.notification_context
    )

    // Navigate immediately to the notification link
    void navigate(notificationLink)

    // If the notification is unread, mark it as read asynchronously in the background
    if (!notification.read_at) {
      void markOneAsRead(notification.id)
    }
  }

  return (
    <Menu
      opened={opened}
      shadow="md"
      width={400}
      arrowSize={16}
      closeOnClickOutside
      withArrow
      onOpen={handleMenuOpen}
      onClose={() => setOpened(false)}
    >
      <Menu.Target>
        <Center pos="relative">
          <ActionIcon variant="subtle" radius="xl" size="sm">
            <IconBellRinging color="white" />
          </ActionIcon>

          {unreadCount > 0 && (
            <Badge pos="absolute" top={-8} right={-10} color="red" size="xs">
              {unreadCount}
            </Badge>
          )}
        </Center>
      </Menu.Target>

      <Menu.Dropdown w={480}>
        <Group justify="end">
          <Button
            size="compact-xs"
            variant="subtle"
            color="blue"
            onClick={() => void markAllAsRead()}
          >
            <FormattedMessage id="notifications.markAllRead" />
          </Button>
        </Group>

        <Menu.Label>
          <FormattedMessage id="notifications" />
        </Menu.Label>

        <ScrollArea h={300} viewportRef={scrollRef} offsetScrollbars>
          {notifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              message={notification.message}
              dateTime={notification.created_at}
              isRead={!!notification.read_at}
              onClick={() => handleNotificationClick(notification)}
              onMarkAsRead={() => void markOneAsRead(notification.id)}
            />
          ))}
        </ScrollArea>

        {hasNextPage && (
          <Button
            size="xs"
            variant="light"
            disabled={isFetchingNextPage}
            fullWidth
            onClick={() => {
              void handleFetchNextPage()
            }}
          >
            {isFetchingNextPage ? (
              <FormattedMessage id="notifications.loading" />
            ) : (
              <FormattedMessage id="notifications.loadMore" />
            )}
          </Button>
        )}
      </Menu.Dropdown>
    </Menu>
  )
}
