import { Flex, Grid, Input } from '@mantine/core'
import { TimeInput } from '@mantine/dates'
import { IconArrowRight, IconClock } from '@tabler/icons-react'
import { useIntl } from 'react-intl'
import { TimeRange } from '@/utils/time'

type TimeFieldProps = {
  value: string
  error?: string
  label: string
  minTime?: string
  onChange: (value: string) => void
}

const TimeField = ({
  value,
  error,
  label,
  minTime,
  onChange
}: TimeFieldProps) => (
  <TimeInput
    leftSection={<IconClock stroke={1.5} />}
    label={label}
    value={value}
    minTime={minTime}
    error={Boolean(error)}
    withErrorStyles
    required
    onChange={(event) => onChange(event.currentTarget.value)}
  />
)

type TimeRangeInputProps = {
  defaultValue?: TimeRange
  error?: string
  onChange: (value: TimeRange) => void
}

export const TimeRangeInput = ({
  defaultValue = { from: '', to: '' },
  onChange,
  error
}: TimeRangeInputProps) => {
  const intl = useIntl()

  const handleChange = (field: keyof TimeRange) => (value: string) => {
    onChange({ ...defaultValue, [field]: value })
  }

  return (
    <Input.Wrapper error={error}>
      <Grid>
        <Grid.Col span={5}>
          <TimeField
            value={defaultValue.from}
            error={error}
            label={intl.formatMessage({
              id: 'logic.notificationSettings.schedule.timeRange.from'
            })}
            onChange={handleChange('from')}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Flex justify="center" align="center" pt="xl" w="100%">
            <IconArrowRight color={error ? 'red' : 'gray'} />
          </Flex>
        </Grid.Col>
        <Grid.Col span={5}>
          <TimeField
            value={defaultValue.to}
            error={error}
            label={intl.formatMessage({
              id: 'logic.notificationSettings.schedule.timeRange.to'
            })}
            minTime={defaultValue.from}
            onChange={handleChange('to')}
          />
        </Grid.Col>
      </Grid>
    </Input.Wrapper>
  )
}
