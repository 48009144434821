import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import { useCallback } from 'react'
import { datasetApi } from '@/api/datasetApi'
import { DatasetLabelingType, DatasetType } from '@/types/dataset'
import { objectToQueryString } from '@/utils/url'

export const datasetQueryKeys = {
  all: ['datasets'] as const,
  list: (params: DatasetsListParams) =>
    [...datasetQueryKeys.all, 'list', params] as const,
  details: (datasetId: string) =>
    [...datasetQueryKeys.all, 'details', datasetId] as const,
  versions: (datasetId: string) =>
    [...datasetQueryKeys.all, 'versions', datasetId] as const,
  versionFiles: (versionId: string) =>
    [...datasetQueryKeys.all, 'version-files', versionId] as const,
  allFiles: (datasetId: string) =>
    [...datasetQueryKeys.all, 'all-files', datasetId] as const,
  uploadStatus: (batchId: string) =>
    [...datasetQueryKeys.all, 'upolad-status', batchId] as const
}

type DatasetsListParams = {
  is_library: boolean
  ordering?: string
  limit?: number
  dataset_type?: DatasetType
  labeling_type?: DatasetLabelingType
}

export const useGetDatasets = (params: DatasetsListParams) => {
  return useInfiniteQuery({
    queryKey: datasetQueryKeys.list(params),
    queryFn: ({ pageParam }) => datasetApi.getDatasets({ pageUrl: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam: `/v1/datasets/?${objectToQueryString({
      limit: 20,
      ...params
    })}`
  })
}

export const useGetDatasetDetails = (datasetId: string, enabled?: boolean) => {
  return useQuery({
    queryKey: datasetQueryKeys.details(datasetId),
    queryFn: () => datasetApi.getDatasetDetails(datasetId),
    enabled
  })
}

const getVersionsInitialPageParam = (datasetId: string) => {
  return `/v1/datasets/${datasetId}/versions/?${objectToQueryString({
    limit: 100,
    ordering: '-created_at'
  })}`
}

export const useGetDatasetVersions = (datasetId: string, enabled?: boolean) => {
  return useInfiniteQuery({
    queryKey: datasetQueryKeys.versions(datasetId),
    queryFn: ({ pageParam }) =>
      datasetApi.getDatasetVersions({ pageUrl: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam: getVersionsInitialPageParam(datasetId),
    enabled
  })
}

export const useGetDatasetVersionsLazy = () => {
  const queryClient = useQueryClient()

  const getDatasetVersionsLazy = useCallback(
    (datasetId: string) => {
      return queryClient.fetchInfiniteQuery({
        queryKey: datasetQueryKeys.versions(datasetId),
        queryFn: ({ pageParam }) =>
          datasetApi.getDatasetVersions({ pageUrl: pageParam }),
        initialPageParam: getVersionsInitialPageParam(datasetId)
      })
    },
    [queryClient]
  )

  return { getDatasetVersionsLazy }
}

type GetDatasetVersionFilesParams = {
  versionId: string
  enabled?: boolean
  listParams?: {
    limit?: number
    ordering?: string
  }
}

export const useGetDatasetVersionFiles = ({
  versionId,
  enabled,
  listParams
}: GetDatasetVersionFilesParams) => {
  return useInfiniteQuery({
    queryKey: datasetQueryKeys.versionFiles(versionId),
    queryFn: ({ pageParam }) =>
      datasetApi.getDatasetFiles({ pageUrl: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam: `/v1/datasets/versions/${versionId}/media/?${objectToQueryString(
      {
        limit: 40,
        ordering: '-created_at',
        ...listParams
      }
    )}`,
    enabled
  })
}

export const useInvalidateFileList = (versionId: string) => {
  const queryClient = useQueryClient()

  const invalidateFileList = useCallback(() => {
    void queryClient.invalidateQueries({
      queryKey: datasetQueryKeys.versionFiles(versionId)
    })
  }, [queryClient, versionId])

  return {
    invalidateFileList
  }
}

export const useCreateDataset = () => {
  return useMutation({
    mutationFn: datasetApi.createDataset
  })
}

export const useCreateDatasetVersion = () => {
  return useMutation({
    mutationFn: datasetApi.createDatasetVersion
  })
}

export const useUpdateDataset = () => {
  return useMutation({
    mutationFn: datasetApi.updateDataset
  })
}

export const useDeleteDataset = () => {
  return useMutation({
    mutationFn: datasetApi.deleteDataset
  })
}

export const useDeleteDatasetVersion = () => {
  return useMutation({
    mutationFn: datasetApi.deleteDatasetVersion
  })
}

export const useUploadFilesToDataset = () => {
  return useMutation({
    mutationFn: datasetApi.uploadFilesToDataset
  })
}
export const useGetUploadStatus = (batchId: string, enabled?: boolean) => {
  return useQuery({
    queryKey: datasetQueryKeys.uploadStatus(batchId),
    queryFn: () => datasetApi.getFileUploadStatus(batchId),
    refetchInterval: (query) => {
      const { data, error } = query.state

      const isFinished =
        data?.status === 'Complete' || data?.status === 'Failed' || error

      return isFinished ? false : 3000
    },
    enabled
  })
}

export const useDeleteFiles = () => {
  return useMutation({
    mutationFn: datasetApi.deleteFiles
  })
}

export const useInvalidateDatasetList = () => {
  const queryClient = useQueryClient()

  const invalidateDatasetList = useCallback(() => {
    void queryClient.invalidateQueries({
      queryKey: [...datasetQueryKeys.all, 'list']
    })
  }, [queryClient])

  return {
    invalidateDatasetList
  }
}

export const useCropDataset = () => {
  return useMutation({
    mutationFn: datasetApi.cropDataset
  })
}
