import {
  CheckIcon,
  Combobox,
  Group,
  Loader,
  Pill,
  PillsInput
} from '@mantine/core'
import { useIntl } from 'react-intl'
import { useEmailMultiselect } from './ useEmailMultiselect'

type EmailMultiselectProps = {
  label: string
  value: string[]
  error?: string
  onChange: (value: string[]) => void
}

export const EmailMultiselect = ({
  label,
  value,
  error,
  onChange
}: EmailMultiselectProps) => {
  const intl = useIntl()

  const {
    combobox,
    loading,
    search,
    empty,
    data,
    isValidEmail,
    handleValueSelect,
    handleValueRemove,
    handleEmailChange,
    handleOnFocus,
    handleOnKeyDown
  } = useEmailMultiselect(value, onChange)

  const showCreateOption =
    empty && search.trim().length > 0 && isValidEmail && !value.includes(search)

  const renderEmailPills = () =>
    value.map((item) => (
      <Pill
        key={item}
        withRemoveButton
        onRemove={() => handleValueRemove(item)}
      >
        {item}
      </Pill>
    ))

  const renderOptions = () => {
    if (loading) {
      return (
        <Combobox.Empty>
          {intl.formatMessage({
            id: 'logic.notificationSettings.email.loading'
          })}
        </Combobox.Empty>
      )
    }

    if (empty && !showCreateOption && search.trim().length > 0) {
      return (
        <Combobox.Empty>
          {intl.formatMessage({
            id: 'logic.notificationSettings.email.invalid'
          })}
        </Combobox.Empty>
      )
    }

    return (
      <>
        {data
          ?.filter((item) =>
            item.toLowerCase().includes(search.trim().toLowerCase())
          )
          .map((item) => (
            <Combobox.Option
              key={item}
              value={item}
              active={value.includes(item)}
            >
              <Group gap="sm">
                {value.includes(item) ? <CheckIcon size={12} /> : null}
                <span>{item}</span>
              </Group>
            </Combobox.Option>
          ))}

        {showCreateOption && (
          <Combobox.Option value="$create">
            {intl.formatMessage(
              { id: 'logic.notificationSettings.email.add' },
              { value: search }
            )}
          </Combobox.Option>
        )}
      </>
    )
  }

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={handleValueSelect}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          label={label}
          error={error}
          rightSection={loading && <Loader size={18} />}
          onClick={() => combobox.openDropdown()}
        >
          <Pill.Group>
            {renderEmailPills()}

            <Combobox.EventsTarget>
              <PillsInput.Field
                value={search}
                placeholder={intl.formatMessage({
                  id: 'logic.notificationSettings.email.placeholder'
                })}
                onFocus={handleOnFocus}
                onBlur={() => combobox.closeDropdown()}
                onChange={handleEmailChange}
                onKeyDown={handleOnKeyDown}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>{renderOptions()}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
