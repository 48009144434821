import { Group, Space, Stack, Text, ThemeIcon, Tooltip } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { ConfidenceSelector } from '../ConfidenceSelector/ConfidenceSelector'

export type DisplayConfidenceThreshold = {
  labelId: string
  labelName: string
  threshold: number
}

type EventSettingsPanelProps = {
  confidenceThresholds: DisplayConfidenceThreshold[]
  onConfidenceChange: (threshold: DisplayConfidenceThreshold) => void
}

export const EventSettingsPanel = ({
  confidenceThresholds,
  onConfidenceChange
}: EventSettingsPanelProps) => {
  return (
    <Stack gap={40} maw={360}>
      <Group align="center" wrap="nowrap" gap="xs">
        <Text size="sm" fw={600}>
          <FormattedMessage id="events.confidence.title" />
        </Text>

        <Tooltip
          w={320}
          label={
            <FormattedMessage
              id="events.confidence.description"
              values={{ br: <Space h={8} /> }}
            />
          }
          multiline
        >
          <ThemeIcon variant="light" radius="xl" size="sm">
            <IconInfoCircle style={{ width: '80%', height: '80%' }} />
          </ThemeIcon>
        </Tooltip>
      </Group>

      {confidenceThresholds.map((threshold) => (
        <ConfidenceSelector
          key={threshold.labelId}
          labelWidth={120}
          label={threshold.labelName}
          value={threshold.threshold}
          onChange={(value) =>
            onConfidenceChange({ ...threshold, threshold: value })
          }
        />
      ))}
    </Stack>
  )
}
