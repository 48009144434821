export type Notification = {
  id: string
  notification_type: NotificationType
  user_id: string
  message: string
  read_at: string | null
  created_at: string
  notification_context: NotificationContext
}

export type NotificationContext =
  | ModelNotificationContext
  | EventNotificationContext
  | FileUploadNotificationContext

export type ModelNotificationContext = {
  appId: string
}

export type EventNotificationContext = {
  appId: string
}

export type FileUploadNotificationContext = {
  appId: string
  modelId: string
}

export enum NotificationType {
  EVENT_DETECTED = 1,
  FILE_ACTION = 2,
  MODEL_TRAINING = 3
}
