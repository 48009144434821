import { Button, Grid, Stack, TextInput, TextInputProps } from '@mantine/core'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useValidateIntegrationUrl } from '@/queries/deviceQueries'
import { EventDestination } from '@/types/deployment'
import { UrlStatus, UrlStatusIndicator } from './UrlStatusIndicator'

type IntegrationInputHandlerProps = TextInputProps & {
  deviceId: string
  source: EventDestination
  isTestButtonDisabled?: boolean
}

export const IntegrationInputHandler = ({
  deviceId,
  source,
  isTestButtonDisabled,
  ...inputProps
}: IntegrationInputHandlerProps) => {
  const { mutateAsync: validateUrl, isPending } = useValidateIntegrationUrl()

  const [urlStatus, setUrlStatus] = useState<UrlStatus>('none')

  const handleTestUrl = async () => {
    setUrlStatus('none')

    try {
      const { status } = await validateUrl({
        deviceId,
        integrationUrl: inputProps.value as string,
        source
      })

      if (status === 'healthy') {
        setUrlStatus('ok')
      } else {
        setUrlStatus('error')
      }
    } catch {
      setUrlStatus('error')
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !isTestButtonDisabled && !isPending) {
      void handleTestUrl()
    }
  }

  return (
    <Stack gap="xs">
      <TextInput
        {...inputProps}
        type="url"
        inputContainer={(children) => (
          <Grid gutter="xs">
            <Grid.Col span="auto">{children}</Grid.Col>

            <Grid.Col span="content">
              <Button
                size="sm"
                disabled={isTestButtonDisabled}
                loading={isPending}
                onClick={() => void handleTestUrl()}
              >
                <FormattedMessage id="integrations.testUrl" />
              </Button>
            </Grid.Col>
          </Grid>
        )}
        onKeyDown={handleKeyDown}
      />

      {urlStatus !== 'none' && <UrlStatusIndicator urlStatus={urlStatus} />}
    </Stack>
  )
}
