import { Button, Modal, Stack, Text } from '@mantine/core'
import { useSessionStorage } from '@mantine/hooks'
import { FormattedMessage } from 'react-intl'
import { TRAINING_UNDER_MAINTENANCE } from '@/config'

const STORAGE_KEY = 'hide-maintenance-modal'

export const PlatformAnnouncements = () => {
  const hasAnnouncements = TRAINING_UNDER_MAINTENANCE === 'true'

  const [hideModal, setHideModal] = useSessionStorage({
    key: STORAGE_KEY,
    defaultValue: false
  })

  if (!hasAnnouncements || hideModal) {
    return null
  }

  return (
    <Modal
      size="lg"
      title={<FormattedMessage id="platformAnnouncements.title" />}
      opened
      onClose={() => setHideModal(true)}
    >
      <Stack gap="lg">
        {TRAINING_UNDER_MAINTENANCE === 'true' && (
          <Text size="sm">
            <FormattedMessage id="platformAnnouncements.trainingUnderMaintenance" />
          </Text>
        )}

        <Button miw={120} onClick={() => setHideModal(true)}>
          <FormattedMessage id="close" />
        </Button>
      </Stack>
    </Modal>
  )
}
