import {
  NormalizedBusinessLogicRule,
  NormalizedStreamBusinessLogic
} from '@/types/businessLogic'
import { ConfidenceThreshold } from '@/types/deployment'

const DEFAULT_CONFIDENCE_THRESHOLD = 0.5

export const extractConfidenceThresholds = (
  initialConfidenceValues: ConfidenceThreshold[],
  normalizedLogics: NormalizedStreamBusinessLogic[]
): ConfidenceThreshold[] => {
  const uniqueLabelIds = new Set<string>()

  // Function to extract label IDs from a single rule
  const extractLabelIdsFromRule = (rule: NormalizedBusinessLogicRule) => {
    rule.label_groups.forEach((group) => {
      uniqueLabelIds.add(group.label_id)

      group.sub_label_ids.forEach((sub_label_id) => {
        uniqueLabelIds.add(sub_label_id)
      })
    })
  }

  // Function to extract label IDs from a list of rules
  const extractLabelIdsFromRules = (rules: NormalizedBusinessLogicRule[]) => {
    rules.forEach(extractLabelIdsFromRule)
  }

  // Iterate over the parameters and extract label IDs from each rule
  normalizedLogics.forEach((logic) => {
    logic.parameters.forEach((parameter) => {
      extractLabelIdsFromRules(parameter.rules)
    })
  })

  // Convert the unique label IDs to confidence thresholds
  return Array.from(uniqueLabelIds).map((labelId) => {
    const existingConfidence = initialConfidenceValues.find(
      (confidence) => confidence.label_id === labelId
    )
    return {
      label_id: labelId,
      threshold: existingConfidence
        ? existingConfidence.threshold
        : DEFAULT_CONFIDENCE_THRESHOLD
    }
  })
}
