import { Annotation } from '@/types/annotation'
import { FileMetadata, FileStatus } from '@/types/dataset'
import { ViewModeToolbar } from './AnnotationToolbar/ViewModeToolbar'

type ViewAnnotationToolbarHandlerProps = {
  file: FileMetadata
  annotation?: Annotation
  isLoading: boolean
  isAnnotationDisabled?: boolean
  onStartLabeling: () => void
  onViewLabeling: () => void
  onRemoveLabeling: () => void
}

export const ViewAnnotationToolbarHandler = ({
  file,
  annotation,
  isLoading,
  isAnnotationDisabled,
  onStartLabeling,
  onViewLabeling,
  onRemoveLabeling
}: ViewAnnotationToolbarHandlerProps) => {
  return (
    <ViewModeToolbar
      fileName={file.name}
      fileType={file.media_type}
      labelFps={annotation?.cvat_fps}
      originalFps={file.original_video_fps ?? undefined}
      labelerEmail={annotation?.user.email}
      labelList={annotation?.dataset_version.labels || []}
      labeledDate={annotation?.updated_at}
      isLabeled={file.status === FileStatus.Labeled}
      showMoreInfo={annotation !== undefined}
      isLoading={isLoading}
      isAnnotationDisabled={isAnnotationDisabled}
      onStartLabeling={onStartLabeling}
      onViewLabeling={onViewLabeling}
      onRemoveLabeling={onRemoveLabeling}
    />
  )
}
