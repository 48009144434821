import { useCombobox } from '@mantine/core'
import { isEmail } from '@mantine/form'
import { useCallback, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

const defaultEmails = [
  'john.doe@example.com',
  'jane.smith@example.com',
  'robert.johnson@example.com',
  'emily.wilson@example.com',
  'michael.brown@example.com',
  'sarah.davis@example.com',
  'david.miller@example.com',
  'lisa.anderson@example.com',
  'james.taylor@example.com',
  'emma.thomas@example.com'
]

function getAsyncData(searchQuery: string, signal: AbortSignal) {
  return new Promise<string[]>((resolve, reject) => {
    signal.addEventListener('abort', () => {
      reject(new Error('Request aborted'))
    })

    setTimeout(() => {
      resolve(
        defaultEmails
          .filter((item) =>
            item.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .slice(0, 5)
      )
    }, Math.random() * 1000)
  })
}

export const CREATE_NEW_OPTION = '$create'

export function useEmailMultiselect(
  value: string[],
  onChange: (value: string[]) => void
) {
  const intl = useIntl()

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active')
  })

  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [empty, setEmpty] = useState(false)
  const [data, setData] = useState<string[] | null>(null)
  const [addedCustomEmails, setAddedCustomEmails] = useState<string[]>([])
  const abortController = useRef<AbortController>()

  const isValidEmail =
    isEmail(
      intl.formatMessage({ id: 'logic.notificationSettings.email.invalid' })
    )(search) === null

  const fetchOptions = useCallback(
    (query: string) => {
      abortController.current?.abort()
      abortController.current = new AbortController()
      setLoading(true)

      getAsyncData(query, abortController.current.signal)
        .then((result) => {
          setData([...addedCustomEmails, ...result])
          setLoading(false)
          setEmpty(result.length === 0)
          abortController.current = undefined
        })
        .catch(() => {})
    },
    [addedCustomEmails]
  )

  const handleValueSelect = useCallback(
    (val: string) => {
      setSearch('')

      if (val === CREATE_NEW_OPTION) {
        setAddedCustomEmails((current) => [...current, search])
        onChange([...value, search])
      } else {
        onChange(
          value.includes(val) ? value.filter((v) => v !== val) : [...value, val]
        )
      }
    },
    [onChange, search, value]
  )

  const handleValueRemove = useCallback(
    (val: string) => {
      onChange(value.filter((v) => v !== val))
      setAddedCustomEmails((current) => current.filter((v) => v !== val))
    },
    [onChange, value]
  )

  const handleEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.currentTarget.value
      combobox.updateSelectedOptionIndex()
      setSearch(newValue)

      if (addedCustomEmails.includes(newValue)) {
        setAddedCustomEmails((prev) =>
          prev.filter((email) => email !== newValue)
        )
      }

      fetchOptions(newValue)
    },
    [addedCustomEmails, combobox, fetchOptions]
  )

  const handleOnFocus = () => {
    if (data === null) {
      fetchOptions(search)
    }
    combobox.openDropdown()
  }

  const handleOnKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Backspace' && search.length === 0) {
        event.preventDefault()
        handleValueRemove(value[value.length - 1])
      } else if (event.key === 'Enter' && isValidEmail) {
        event.preventDefault()
        handleValueSelect(search)
      } else if (event.key === 'Enter' && !isValidEmail) {
        event.preventDefault()
      }
    },
    [search, isValidEmail, handleValueRemove, handleValueSelect, value]
  )

  return {
    combobox,
    loading,
    search,
    empty,
    data,
    value,
    isValidEmail,
    handleEmailChange,
    handleValueSelect,
    handleValueRemove,
    setSearch,
    fetchOptions,
    handleOnFocus,
    handleOnKeyDown
  }
}
