import { Modal } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

type NotificationSettingsModalProps = {
  opened: boolean
  onClose: () => void
  children: React.ReactNode
}

export const NotificationSettingsModal = ({
  opened,
  onClose,
  children
}: NotificationSettingsModalProps) => {
  return (
    <Modal
      opened={opened}
      size="lg"
      title={<FormattedMessage id="logic.notificationSettings.title" />}
      onClose={onClose}
    >
      {children}
    </Modal>
  )
}
