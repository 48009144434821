import { useInfiniteQuery, useMutation } from '@tanstack/react-query'
import { notificationApi } from '@/api/notificationApi'
import { objectToQueryString } from '@/utils/url'

export const notificationQueryKeys = {
  all: ['notifications'] as const,
  list: () => [...notificationQueryKeys.all, 'list'] as const
}

export const useGetNotifications = () => {
  return useInfiniteQuery({
    queryKey: notificationQueryKeys.list(),
    queryFn: ({ pageParam }) =>
      notificationApi.getNotifications({ pageUrl: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam: `/v1/notifications/?${objectToQueryString({
      limit: 5
    })}`
  })
}

// Mutation to mark a single notification as read
export const useMarkNotificationAsRead = () => {
  return useMutation({
    mutationFn: notificationApi.markNotificationAsRead
  })
}

// Mutation to mark all notifications as read
export const useMarkAllNotificationsAsRead = () => {
  return useMutation({
    mutationFn: notificationApi.markAllNotificationsAsRead
  })
}
