import {
  Anchor,
  Badge,
  Box,
  Button,
  Group,
  NumberInput,
  Popover,
  Space,
  Stack,
  Text,
  Tooltip
} from '@mantine/core'
import { IconStopwatch } from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

type SetTimerProps = {
  value: number | null
  onChange: (value: number | null) => void
}

export const SetTimer = ({ value, onChange }: SetTimerProps) => {
  const intl = useIntl()

  const [isPopoverOpened, setIsPopoverOpened] = useState(false)

  const [timerValue, setTimerValue] = useState<number | ''>(
    value === null ? '' : value
  )

  const handleSave = () => {
    onChange(timerValue === '' ? null : timerValue)
    setIsPopoverOpened(false)
  }

  return (
    <Popover
      opened={isPopoverOpened}
      width={300}
      position="bottom"
      shadow="md"
      trapFocus
      onChange={setIsPopoverOpened}
    >
      <Popover.Target>
        <Tooltip
          w={220}
          label={<FormattedMessage id="logic.timer.description" />}
          disabled={isPopoverOpened}
          multiline
        >
          <Anchor
            component="button"
            type="button"
            size="xs"
            onClick={() => setIsPopoverOpened((o) => !o)}
          >
            <Group align="center" gap={4}>
              <IconStopwatch size={14} />

              <FormattedMessage id="logic.timer.set" />

              {value !== null && value !== 0 && (
                <Badge
                  size="xs"
                  ml={2}
                  styles={{
                    label: {
                      textTransform: 'lowercase'
                    }
                  }}
                >
                  <FormattedMessage id="logic.timer.value" values={{ value }} />
                </Badge>
              )}
            </Group>
          </Anchor>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown bg="var(--mantine-color-body)">
        <Stack gap="lg">
          <Text size="sm" fw="bold">
            <FormattedMessage id="logic.timer" />
          </Text>

          <Box>
            <Text size="xs" mb="sm">
              <FormattedMessage id="logic.timer.description" />
            </Text>

            <NumberInput
              size="xs"
              value={timerValue}
              placeholder={intl.formatMessage({
                id: 'logic.timer.inputPlaceholder'
              })}
              min={0}
              max={999999} // around 11.5 days
              onChange={(value) =>
                setTimerValue(typeof value === 'number' ? value : '')
              }
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleSave()
                }
              }}
            />
          </Box>

          <Button size="compact-xs" onClick={handleSave}>
            <FormattedMessage id="save" />
          </Button>
        </Stack>

        <Space h="xs" />
      </Popover.Dropdown>
    </Popover>
  )
}
