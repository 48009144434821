import { Modal } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { Schedule } from './ScheduleItem/ScheduleItem'

type ScheduleModalProps = {
  opened: boolean
  schedule?: Schedule
  children: React.ReactNode
  onClose: () => void
}

export const ScheduleModal = ({
  opened,
  onClose,
  schedule,
  children
}: ScheduleModalProps) => {
  return (
    <Modal
      opened={opened}
      size="lg"
      title={
        <FormattedMessage
          id={
            schedule
              ? 'logic.notificationSettings.schedule.update'
              : 'logic.notificationSettings.schedule.add'
          }
        />
      }
      onClose={onClose}
    >
      {children}
    </Modal>
  )
}
