import {
  ApplicationNestedPath,
  ModelNestedPath,
  buildAppLink,
  buildModelDetailsLink
} from '@/router/paths'
import {
  EventNotificationContext,
  FileUploadNotificationContext,
  ModelNotificationContext,
  NotificationContext,
  NotificationType
} from '@/types/notifications'

export const getNotificationLink = (
  notificationType: NotificationType,
  notificationContext: NotificationContext
): string => {
  if (notificationType === NotificationType.MODEL_TRAINING) {
    const { appId } = notificationContext as ModelNotificationContext
    return buildAppLink(appId, ApplicationNestedPath.models)
  }

  if (notificationType === NotificationType.EVENT_DETECTED) {
    const { appId } = notificationContext as EventNotificationContext
    return buildAppLink(appId, ApplicationNestedPath.events)
  }

  if (notificationType === NotificationType.FILE_ACTION) {
    const { appId, modelId } =
      notificationContext as FileUploadNotificationContext
    return buildModelDetailsLink(appId, modelId, ModelNestedPath.labelData)
  }

  return '/'
}
