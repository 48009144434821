import { FileRejection } from '@mantine/dropzone'
import { useDisclosure } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useModelContext } from '@/providers/ModelContext'
import {
  useGetUploadStatus,
  useInvalidateFileList,
  useUploadFilesToDataset
} from '@/queries/datasetQueries'
import { showToast } from '@/theme/notifications'
import { DatasetVersion, DatasetVersionStatus } from '@/types/dataset'
import { ConfirmUploadModal } from './ConfirmUploadModal/ConfirmUploadModal'
import { UploadProgressModal } from './UploadProgressModal/UploadProgressModal'
import { UploadableMediaList } from './UploadableMediaList'

type UploadableMediaListHandlerProps = {
  version: DatasetVersion
}

export const UploadableMediaListHandler = ({
  version
}: UploadableMediaListHandlerProps) => {
  const intl = useIntl()
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([])
  const [uploadBatchId, setUploadBatchId] = useState<string>('')
  const [
    isConfirmModalOpened,
    { open: openConfirmModal, close: closeConfirmModal }
  ] = useDisclosure(false)

  const [
    isProgressModalOpened,
    { open: openProgressModal, close: closeProgressModal }
  ] = useDisclosure(false)

  const { mutateAsync: uploadFiles, isPending } = useUploadFilesToDataset()

  const { data: uploadStatusData } = useGetUploadStatus(
    uploadBatchId,
    uploadBatchId !== ''
  )

  const isUploadFinished =
    uploadStatusData?.status === 'Complete' ||
    uploadStatusData?.status === 'Failed'

  const { invalidateFileList } = useInvalidateFileList(version.id)
  const { application } = useApplicationContext()
  const { model } = useModelContext()
  useEffect(() => {
    if (
      uploadStatusData?.uploaded_files !== 0 &&
      uploadStatusData?.status === 'Pending'
    ) {
      // Refetch file list as more files were uploaded
      invalidateFileList()
    }

    if (isUploadFinished) {
      invalidateFileList()

      if (uploadStatusData?.failed_files > 0) {
        openProgressModal()

        showToast(
          intl.formatMessage(
            { id: 'datasets.upload.filesPartiallyUploaded' },
            { count: uploadStatusData?.failed_files }
          ),
          'error'
        )
      } else {
        setUploadBatchId('')
        closeProgressModal()

        showToast(
          intl.formatMessage({ id: 'datasets.upload.completed' }),
          'success'
        )
      }
    }
  }, [
    intl,
    uploadStatusData,
    isUploadFinished,
    openProgressModal,
    closeProgressModal,
    invalidateFileList
  ])

  const handleDrop = (files: File[]) => {
    setAcceptedFiles(files)
    openConfirmModal()
  }

  const handleReject = (fileRejections: FileRejection[]) => {
    setFileRejections(fileRejections)
    openConfirmModal()
  }

  const handleCloseConfirmModal = () => {
    closeConfirmModal()
    setAcceptedFiles([])
    setFileRejections([])
  }

  const handleConfirmUpload = async () => {
    try {
      const notification_context = {
        appId: application?.id || '',
        modelId: model?.id || ''
      }
      const { data } = await uploadFiles({
        versionId: version.id,
        media_files: acceptedFiles,
        notification_context: notification_context
      })

      setUploadBatchId(data.batch_id)
      closeConfirmModal()
      openProgressModal()
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'datasets.upload.failed' })

      showToast(message, 'error')
    }
  }

  const handleCloseProgressModal = () => {
    if (isUploadFinished) {
      setUploadBatchId('')
    }

    closeProgressModal()
  }

  return (
    <>
      <UploadableMediaList
        versionId={version.id}
        isUploading={uploadStatusData?.status === 'Pending'}
        isLocked={version.status === DatasetVersionStatus.Completed}
        onDrop={handleDrop}
        onReject={handleReject}
        onShowUploadProgress={openProgressModal}
      />

      <ConfirmUploadModal
        opened={isConfirmModalOpened}
        acceptedFiles={acceptedFiles}
        fileRejections={fileRejections}
        isLoading={isPending}
        isConfirmDisabled={isPending || acceptedFiles.length === 0}
        onClose={handleCloseConfirmModal}
        onConfirmUpload={() => void handleConfirmUpload()}
      />

      <UploadProgressModal
        totalFiles={uploadStatusData?.total_files || 0}
        uploadedFies={uploadStatusData?.uploaded_files || 0}
        isCompleted={isUploadFinished}
        failedFileNames={uploadStatusData?.failed_file_names || []}
        opened={isProgressModalOpened}
        onClose={handleCloseProgressModal}
      />
    </>
  )
}
