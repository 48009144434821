import {
  Routes as BrowserRoutes,
  Navigate,
  Outlet,
  Route,
  useLocation
} from 'react-router-dom'
import { ApplicationsPage } from '@/components/applications/ApplicationsPage'
import { DatasetDetailsPage } from '@/components/datasets/DatasetDetailsPage'
import { SelectDatasetPage } from '@/components/datasets/SelectDatasetPage'
import { EditDeploymentPage } from '@/components/deployments/deployment-editor/EditDeploymentPage'
import { DeploymentsPage } from '@/components/deployments/deployment-list/DeploymentsPage'
import { DeviceActivationPage } from '@/components/devices/DeviceActivationPage/DeviceActivationPage'
import { EventsPage } from '@/components/events/EventsPage'
import {
  AppDetailsLayoutWrapper,
  AppDetailsProvider,
  ModelDetailsLayoutWrapper,
  ModelDetailsProvider
} from '@/components/layout/AppDetailsLayout/AppDetailsLayoutWrapper'
import { AuthLayout } from '@/components/layout/AuthLayout/AuthLayout'
import { PageContainer } from '@/components/layout/PageContainer/PageContainer'
import { SubscriptionsLayout } from '@/components/layout/SubscriptionsLayout/SubscriptionsLayout'
import { BaseModelSelectionPage } from '@/components/models/BaseModelSelectionPage'
import { ModelDetailsPage } from '@/components/models/ModelDetails/ModelDetailsPage'
import { ModelStatusGuard } from '@/components/models/ModelDetails/ModelStatusGuard'
import { ModelsPage } from '@/components/models/ModelsPage'
import { MySubscriptionPage } from '@/components/subscriptions/MySubscriptionPage'
import { ChooseSubscriptionPage } from '@/components/subscriptions/choose-plan/ChooseSubscriptionPage'
import { TrainingDetailsPage } from '@/components/trainings/TrainingDetailsPage'
import { AcceptWorkspaceInvitation } from '@/components/workspaces/AcceptWorkspaceInvitation'
import { CreateWorkspaceHandler } from '@/components/workspaces/CreateWorkspaceHandler'
import { WorkspacePage } from '@/components/workspaces/WorkspacePage'
import {
  DISABLE_SUBSCRIPTION_SELECTION_ON_SIGNUP,
  DISABLE_WORKSPACES
} from '@/config'
import { PageLayout } from '../components/layout/MainLayout/MainLayout'
import { LoginPage } from '../components/login/LoginPage'
import { ForgotPasswordPage } from '../components/password-reset/ForgotPasswordPage'
import { SetNewPasswordPage } from '../components/password-reset/SetNewPasswordPage'
import { SignUpPage } from '../components/signup/SignUpPage'
import { CompleteSignUpPage } from '../components/signup/complete-registration/CompleteSignUpPage'
import { PageNotFound } from '../components/ui-shared/PageNotFound/PageNotFound'
import { useSession } from '../session/SessionContext'
import { ApplicationNestedPath, ModelNestedPath, RouterPath } from './paths'

const AuthRoute = () => {
  const { isAuthenticated } = useSession()

  if (isAuthenticated) {
    return <Navigate to={RouterPath.root} replace />
  }

  return <Outlet />
}

const PrivateRoute = () => {
  const { isAuthenticated, hasSubscription, hasWorkspace } = useSession()
  const location = useLocation()

  // Remember the user's intended URL for redirecting them there after they log in
  const redirectTo = location.pathname + location.search

  if (!isAuthenticated) {
    return <Navigate to={RouterPath.login} state={{ redirectTo }} replace />
  }

  if (!hasWorkspace && DISABLE_WORKSPACES !== 'true') {
    return (
      <AuthLayout>
        <CreateWorkspaceHandler />
      </AuthLayout>
    )
  }

  if (DISABLE_SUBSCRIPTION_SELECTION_ON_SIGNUP === 'true') {
    console.warn(
      'Subscription selection is disabled, add VITE_DISABLE_SUBSCRIPTION_SELECTION_ON_SIGNUP=false to local.env file enable it.'
    )
  }

  return hasSubscription ||
    DISABLE_SUBSCRIPTION_SELECTION_ON_SIGNUP === 'true' ? (
    <Outlet />
  ) : (
    <SubscriptionsLayout>
      <ChooseSubscriptionPage />
    </SubscriptionsLayout>
  )
}

export const Routes = () => {
  return (
    <BrowserRoutes>
      <Route element={<PrivateRoute />}>
        <Route path={RouterPath.root} element={<PageLayout />}>
          <Route element={<Navigate to={RouterPath.apps} />} index />
          <Route path={RouterPath.apps} element={<ApplicationsPage />} />
          <Route
            path={RouterPath.mySubscription}
            element={<MySubscriptionPage />}
          />
          <Route
            path={RouterPath.deviceClaim}
            element={<DeviceActivationPage />}
          />
          {DISABLE_WORKSPACES !== 'true' && (
            <>
              <Route path={RouterPath.workspace} element={<WorkspacePage />} />
              <Route
                path={RouterPath.workspaceAcceptInvite}
                element={<AcceptWorkspaceInvitation />}
              />
            </>
          )}
        </Route>

        <Route path={RouterPath.appDetails} element={<AppDetailsProvider />}>
          <Route element={<AppDetailsLayoutWrapper />}>
            <Route
              element={<Navigate to={ApplicationNestedPath.models} replace />}
              index
            />

            <Route
              path={ApplicationNestedPath.models}
              element={<ModelsPage />}
            />
            <Route
              path={ApplicationNestedPath.deployments}
              element={<DeploymentsPage />}
            />
            <Route
              path={ApplicationNestedPath.createDeployment}
              element={<EditDeploymentPage />}
            />
            <Route
              path={ApplicationNestedPath.events}
              element={
                <PageContainer fullWidth>
                  <EventsPage />
                </PageContainer>
              }
            />
          </Route>

          <Route
            path={ApplicationNestedPath.modelDetails}
            element={<ModelDetailsProvider />}
          >
            <Route element={<ModelDetailsLayoutWrapper />}>
              <Route element={<ModelDetailsPage />} index />

              {/* ModelStatusGuard: Ensure the following routes cannot be accessed after the model is trained */}
              <Route element={<ModelStatusGuard />}>
                <Route
                  path={ModelNestedPath.selectBaseModel}
                  element={<BaseModelSelectionPage />}
                />

                <Route
                  path={ModelNestedPath.selectDataset}
                  element={<SelectDatasetPage />}
                />

                <Route
                  path={ModelNestedPath.labelData}
                  element={<DatasetDetailsPage />}
                />
              </Route>

              <Route
                path={ModelNestedPath.training}
                element={<TrainingDetailsPage />}
              />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route element={<AuthRoute />}>
        <Route path={RouterPath.login} element={<LoginPage />} />
        <Route path={RouterPath.signup} element={<SignUpPage />} />
        <Route
          path={RouterPath.signupComplete}
          element={<CompleteSignUpPage />}
        />
        <Route
          path={RouterPath.forgotPassword}
          element={<ForgotPasswordPage />}
        />
        <Route
          path={RouterPath.setNewPassword}
          element={<SetNewPasswordPage />}
        />
      </Route>

      <Route path="*" element={<PageNotFound homeLink={RouterPath.root} />} />
    </BrowserRoutes>
  )
}
