import { Button, Group, Stack, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import { FormattedMessage, useIntl } from 'react-intl'
import { TimeRange, parseTimeToMinutes } from '@/utils/time'
import { TimeRangeInput } from './TimeRangeInput/TimeRangeInput'
import { WeekdaySelector } from './WeekdaySelector/WeekdaySelector'
import { WeekdayValue } from './WeekdaySelector/useWeekday'

export type ScheduleFormValues = {
  timeRange: TimeRange
  selectedDays: WeekdayValue[]
}
type ScheduleFormProps = {
  onClose: () => void
  onSave: (val: { timeRange: TimeRange; selectedDays: WeekdayValue[] }) => void
  schedule?: ScheduleFormValues
}

export const ScheduleForm = ({
  onClose,
  onSave,
  schedule
}: ScheduleFormProps) => {
  const intl = useIntl()
  const form = useForm<ScheduleFormValues>({
    mode: 'uncontrolled',
    initialValues: schedule || {
      timeRange: {
        from: '',
        to: ''
      },
      selectedDays: []
    },
    validate: {
      timeRange: (value) => {
        if (!value.from || !value.to) {
          return intl.formatMessage({
            id: 'logic.notificationSettings.schedule.timeRange.error.required'
          })
        }

        const fromMinutes = parseTimeToMinutes(value.from)
        const toMinutes = parseTimeToMinutes(value.to)

        if (fromMinutes > toMinutes) {
          return intl.formatMessage({
            id: 'logic.notificationSettings.schedule.timeRange.error.fromBeforeTo'
          })
        }
        if (fromMinutes === toMinutes) {
          return intl.formatMessage({
            id: 'logic.notificationSettings.schedule.timeRange.error.sameTime'
          })
        }

        return null
      },
      selectedDays: (value) => {
        if (value.length === 0) {
          return intl.formatMessage({
            id: 'logic.notificationSettings.schedule.weekday.error.required'
          })
        }
        return null
      }
    }
  })

  const handleTimeRangeChange = (value: TimeRange) => {
    form.setFieldValue('timeRange', {
      from: value.from,
      to: value.to
    })
  }

  const handleSelectedDaysChange = (value: WeekdayValue[]) => {
    form.setFieldValue('selectedDays', value)
  }

  return (
    <form
      noValidate
      onSubmit={form.onSubmit((values) => {
        onSave(values)
      })}
    >
      <Stack gap="sm">
        <Text>
          <FormattedMessage id="logic.notificationSettings.schedule.add.description" />
        </Text>

        <Text size="sm">
          <FormattedMessage
            id="logic.notificationSettings.schedule.timezone"
            values={{
              value: 'Central European Standard Time',
              strong: (chunks) => (
                <Text fw={700} td="underline" span>
                  {chunks}
                </Text>
              )
            }}
          />
        </Text>

        <TimeRangeInput
          key={form.key('timeRange')}
          {...form.getInputProps('timeRange')}
          onChange={handleTimeRangeChange}
        />

        <WeekdaySelector
          key={form.key('selectedDays')}
          {...form.getInputProps('selectedDays')}
          onChange={handleSelectedDaysChange}
        />

        <Group justify="end" mt="md">
          <Button miw={120} variant="default" onClick={onClose}>
            <FormattedMessage id="cancel" />
          </Button>

          <Button miw={120} type="submit">
            <FormattedMessage id="save" />
          </Button>
        </Group>
      </Stack>
    </form>
  )
}
