import { UseFormReturnType } from '@mantine/form'
import { v4 as uuidv4 } from 'uuid'
import { NotificationSettingsFormValues } from '../NotificationSettingsForm'
import { Schedule } from '../Schedule/ScheduleItem/ScheduleItem'

type NotificationSettingsForm = UseFormReturnType<
  NotificationSettingsFormValues,
  (values: NotificationSettingsFormValues) => NotificationSettingsFormValues
>

type UseScheduleProps = {
  form: NotificationSettingsForm
  selectedSchedule: { index: number; schedule: Schedule } | null
  closeScheduleModal: () => void
}

export const useSchedule = ({
  form,
  selectedSchedule,
  closeScheduleModal
}: UseScheduleProps) => {
  const addSchedule = (schedule: Schedule) => {
    if (selectedSchedule !== null) {
      updateSchedule(selectedSchedule.index, schedule)
    } else {
      form.insertListItem('schedules', {
        uuid: uuidv4(),
        schedule,
        isEnabled: true
      })
    }
    closeScheduleModal()
  }

  const removeSchedule = (index: number) => {
    form.removeListItem('schedules', index)
  }

  const toggleSchedule = (index: number) => {
    form.setFieldValue(
      `schedules.${index}.isEnabled`,
      !form.values.schedules[index].isEnabled
    )
  }

  const updateSchedule = (index: number, updatedSchedule: Schedule) => {
    form.setFieldValue(`schedules.${index}.schedule`, updatedSchedule)
  }

  return {
    addSchedule,
    toggleSchedule,
    removeSchedule,
    updateSchedule
  }
}
