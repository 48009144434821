import { ActionIcon, Checkbox, Group, Text, Tooltip } from '@mantine/core'
import { IconPencil, IconTrash } from '@tabler/icons-react'
import { useIntl } from 'react-intl'
import { TimeRange, convertTo12Hour } from '@/utils/time'
import { ScheduleToggleConfig } from '../../NotificationSettingsForm'
import { WEEKDAYS, WeekdayValue } from '../WeekdaySelector/useWeekday'

export type Schedule = {
  timeRange: TimeRange
  selectedDays: WeekdayValue[]
}

type ScheduleItemProps = {
  item: ScheduleToggleConfig
  onToggle: () => void
  onRemove: () => void
  onUpdateClick: () => void
}

const ScheduleLabel = ({
  timeRange,
  selectedDays
}: {
  timeRange: TimeRange
  selectedDays: WeekdayValue[]
}) => {
  return (
    <Group gap="xs">
      <Text size="sm" fw={700} inline>
        {convertTo12Hour(timeRange.from)} - {convertTo12Hour(timeRange.to)}
      </Text>
      <Text size="xs" c="dimmed" inline>
        {selectedDays.map((day) => WEEKDAYS[day - 1].label).join(', ')}
      </Text>
    </Group>
  )
}

export const ScheduleItem = ({
  item,
  onToggle,
  onRemove,
  onUpdateClick
}: ScheduleItemProps) => {
  const intl = useIntl()
  return (
    <Group gap="lg">
      <Checkbox
        styles={{ body: { alignItems: 'center' } }}
        checked={item.isEnabled}
        label={
          <ScheduleLabel
            timeRange={item.schedule.timeRange}
            selectedDays={item.schedule.selectedDays}
          />
        }
        onChange={onToggle}
      />
      <Group gap={4}>
        <Tooltip
          label={intl.formatMessage({
            id: 'logic.notificationSettings.schedule.update'
          })}
        >
          <ActionIcon size="xs" variant="subtle" onClick={onUpdateClick}>
            <IconPencil />
          </ActionIcon>
        </Tooltip>
        <Tooltip
          label={intl.formatMessage({
            id: 'logic.notificationSettings.schedule.remove'
          })}
        >
          <ActionIcon size="xs" variant="subtle" onClick={onRemove}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Group>
  )
}
