import { Button, Grid, Group, Menu, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { getFormattedDateWithTime } from '@/utils/date'

type NotificationItemProps = {
  message: string
  dateTime: string
  isRead?: boolean
  onMarkAsRead: () => void
  onClick: () => void
}

export const NotificationItem = ({
  message,
  dateTime,
  isRead,
  onMarkAsRead,
  onClick
}: NotificationItemProps) => {
  const handleMarkAsRead = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation() // Prevents the menu from closing
    onMarkAsRead()
  }

  return (
    <Menu.Item component="a" onClick={onClick}>
      <Grid align="center" justify="space-between" style={{ width: '100%' }}>
        <Grid.Col span={8}>
          <Text size="sm" fw={isRead ? 'normal' : 'bold'}>
            {message}
          </Text>

          <Text size="xs" c="dimmed">
            {getFormattedDateWithTime(dateTime)}
          </Text>
        </Grid.Col>

        <Grid.Col span={4}>
          <Group justify="end">
            {!isRead && (
              <Button
                variant="subtle"
                size="compact-xs"
                color="blue"
                onClick={handleMarkAsRead}
              >
                <FormattedMessage id="notifications.markRead" />
              </Button>
            )}
          </Group>
        </Grid.Col>
      </Grid>
    </Menu.Item>
  )
}
