import { Chip, Group, Input, Select, Stack } from '@mantine/core'
import { useIntl } from 'react-intl'
import {
  REPEAT_OPTIONS,
  WEEKDAYS,
  WeekdayValue,
  useWeekdayLogic
} from './useWeekday'

const CHIP_STYLES = {
  iconWrapper: { display: 'none' },
  label: {
    height: 'var(--chip-size)',
    width: 'var(--chip-size)',
    paddingInline: '0px',
    justifyContent: 'center',
    fontSize: 'var(--chip-font-size)'
  }
}

type WeekdaySelectorProps = {
  defaultValue?: WeekdayValue[]
  error?: string
  onChange: (value: WeekdayValue[]) => void
}

export const WeekdaySelector = ({
  defaultValue,
  error,
  onChange
}: WeekdaySelectorProps) => {
  const intl = useIntl()
  const { repeats, handleRepeatsChange, handleDaysChange, getRepeatOption } =
    useWeekdayLogic({
      initialValue: defaultValue ?? [],
      onChange
    })

  return (
    <Input.Wrapper error={error}>
      <Stack gap="xs" mb="xs">
        <Select
          error={Boolean(error)}
          label={intl.formatMessage({
            id: 'logic.notificationSettings.schedule.repeats'
          })}
          data={REPEAT_OPTIONS.map(getRepeatOption)}
          value={repeats?.value || null}
          withErrorStyles
          searchable
          required
          onChange={(_value, option) => handleRepeatsChange(option)}
        />

        <Chip.Group
          value={defaultValue?.map((value) => value.toString())}
          multiple
          onChange={handleDaysChange}
        >
          <Group gap="xs">
            {WEEKDAYS.map(({ value, label }) => (
              <Chip
                key={value}
                size="xl"
                value={value.toString()}
                styles={CHIP_STYLES}
              >
                {label}
              </Chip>
            ))}
          </Group>
        </Chip.Group>
      </Stack>
    </Input.Wrapper>
  )
}
