import { Group, Slider, Text, rem } from '@mantine/core'
import { ReactNode } from 'react'
import { useIntl } from 'react-intl'

const MIN_VALUE = 0.3
const MAX_VALUE = 1
const STEP = 0.1
const DEFAULT_VALUE = 0.5
const DEFAULT_LABEL_WIDTH = 100

type ConfidenceSelectorProps = {
  label: string | ReactNode
  value: number
  isUpperCase?: boolean
  labelWidth?: number
  onChange: (value: number) => void
}

export const ConfidenceSelector = ({
  label,
  value,
  labelWidth = DEFAULT_LABEL_WIDTH,
  isUpperCase = true,
  onChange
}: ConfidenceSelectorProps) => {
  const intl = useIntl()

  return (
    <Group w="100%" wrap="nowrap">
      <Text
        miw={labelWidth}
        size="xs"
        fw="bold"
        styles={{
          root: {
            textTransform: isUpperCase ? 'uppercase' : 'none'
          }
        }}
      >
        {label}
      </Text>

      <Slider
        w="100%"
        size="xs"
        marks={[
          {
            value: MIN_VALUE,
            label: intl.formatMessage({ id: 'events.confidence.low' })
          },
          {
            value: MAX_VALUE,
            label: intl.formatMessage({ id: 'events.confidence.high' })
          }
        ]}
        value={value}
        min={MIN_VALUE}
        max={MAX_VALUE}
        step={STEP}
        defaultValue={DEFAULT_VALUE}
        styles={(theme) => ({
          label: {
            fontSize: rem(11),
            fontWeight: 500,
            top: '-30px',
            backgroundColor: 'transparent',
            color: theme.colors.dark[5]
          },
          markLabel: {
            fontSize: rem(11),
            textTransform: 'lowercase'
          }
        })}
        labelAlwaysOn
        onChange={onChange}
      />
    </Group>
  )
}
