import { Anchor, Badge, Group, Tooltip } from '@mantine/core'
import { IconLogout } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

type AlertOnExitToggleProps = {
  checked: boolean
  disabled?: boolean
  disabledTooltip?: string | ReactNode
  onChange: (value: boolean) => void
}

export const AlertOnExitToggle = ({
  checked,
  disabled,
  disabledTooltip,
  onChange
}: AlertOnExitToggleProps) => {
  return (
    <Tooltip
      w={220}
      label={
        disabled && disabledTooltip ? (
          disabledTooltip
        ) : (
          <FormattedMessage id="logic.alertOnExit.tooltip" />
        )
      }
      multiline
    >
      <Anchor
        disabled={disabled}
        component="button"
        type="button"
        size="xs"
        c={disabled ? 'gray' : undefined}
        onClick={() => onChange(!checked)}
      >
        <Group align="center" gap={4}>
          <IconLogout size={14} />

          <FormattedMessage id="logic.alertOnExit" />

          {checked && (
            <Badge size="xs" ml={2}>
              <FormattedMessage id="logic.alertOnExit.on" />
            </Badge>
          )}
        </Group>
      </Anchor>
    </Tooltip>
  )
}
