import { Group, Image, Stack, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { SelectableCard } from '@/components/ui-shared/SelectableCard/SelectableCard'
import { SavedEventEndpoint } from '../IntegrationSelection'
import { integrationConfig } from '../config'

type IntegrationPreviewCardProps = {
  eventEndpoints: SavedEventEndpoint[]
}

export const IntegrationPreviewCard = ({
  eventEndpoints
}: IntegrationPreviewCardProps) => {
  return (
    <SelectableCard height={175}>
      <Stack justify="center" h="100%">
        {eventEndpoints.length === 0 && (
          <Text size="xs">
            <FormattedMessage id="integrations.noEventNotifications" />
          </Text>
        )}

        {eventEndpoints.map((eventEndpoint) => (
          <Group key={eventEndpoint.service} gap="xs" wrap="nowrap">
            <Image
              src={integrationConfig[eventEndpoint.service].logo}
              w={24}
              h={24}
              fit="contain"
            />

            <Text size="xs" truncate>
              <FormattedMessage
                id={integrationConfig[eventEndpoint.service].name}
              />
            </Text>
          </Group>
        ))}
      </Stack>
    </SelectableCard>
  )
}
