import { ComboboxItem } from '@mantine/core'
import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'

export type WeekdayValue = 1 | 2 | 3 | 4 | 5 | 6 | 7
export type WeekdayLabel = 'Su' | 'M' | 'T' | 'W' | 'Th' | 'F' | 'Sa'

type WeekdayOption = {
  readonly value: WeekdayValue
  readonly label: WeekdayLabel
}

export const WEEKDAYS: readonly WeekdayOption[] = [
  { value: 1, label: 'M' },
  { value: 2, label: 'T' },
  { value: 3, label: 'W' },
  { value: 4, label: 'Th' },
  { value: 5, label: 'F' },
  { value: 6, label: 'Sa' },
  { value: 7, label: 'Su' }
] as const

export const enum RepeatOption {
  EveryDay = 'every_day',
  Workdays = 'every_workdays',
  Weekend = 'every_weekend',
  Custom = 'custom'
}

const WORKDAYS = WEEKDAYS.slice(0, 5).map((day) => day.value)
const WEEKEND = [WEEKDAYS[5].value, WEEKDAYS[6].value] as const

export const REPEAT_OPTIONS = [
  RepeatOption.EveryDay,
  RepeatOption.Workdays,
  RepeatOption.Weekend,
  RepeatOption.Custom
]

const DAYS_MAP = {
  [RepeatOption.EveryDay]: WEEKDAYS.map(({ value }) => value),
  [RepeatOption.Workdays]: WORKDAYS,
  [RepeatOption.Weekend]: WEEKEND,
  [RepeatOption.Custom]: []
} as const

const isWorkdays = (days: WeekdayValue[]): boolean =>
  days.length === WORKDAYS.length && WORKDAYS.every((day) => days.includes(day))

const isWeekend = (days: WeekdayValue[]): boolean =>
  days.length === WEEKEND.length && WEEKEND.every((day) => days.includes(day))

const getRepeatOptionFromDays = (days: WeekdayValue[]): RepeatOption => {
  if (days.length === WEEKDAYS.length) return RepeatOption.EveryDay
  if (isWorkdays(days)) return RepeatOption.Workdays
  if (isWeekend(days)) return RepeatOption.Weekend
  return RepeatOption.Custom
}

type UseWeekdayLogicProps = {
  initialValue: WeekdayValue[]
  onChange: (value: WeekdayValue[]) => void
}

type UseWeekdayLogicReturn = {
  repeats: ComboboxItem | null
  handleRepeatsChange: (value: ComboboxItem) => void
  handleDaysChange: (value: string[]) => void
  getRepeatOption: (value: RepeatOption) => ComboboxItem
}

export const useWeekdayLogic = ({
  initialValue,
  onChange
}: UseWeekdayLogicProps): UseWeekdayLogicReturn => {
  const intl = useIntl()

  const getRepeatOption = useCallback(
    (value: RepeatOption): ComboboxItem => ({
      value,
      label: intl.formatMessage({
        id: `logic.notificationSettings.schedule.repeats.${value}`
      })
    }),
    [intl]
  )

  const [repeats, setRepeats] = useState<ComboboxItem | null>(() => {
    const initialRepeat = getRepeatOptionFromDays(initialValue)
    return getRepeatOption(initialRepeat)
  })

  const handleRepeatsChange = useCallback(
    (value: ComboboxItem) => {
      setRepeats(value)
      const newDays = [...DAYS_MAP[value.value as RepeatOption]]
      onChange(newDays)
    },
    [onChange]
  )

  const handleDaysChange = useCallback(
    (value: string[]) => {
      const sortedValue = value
        .map((day) => Number(day) as WeekdayValue)
        .sort((a, b) => a - b)
      onChange(sortedValue)
      const newRepeat = getRepeatOptionFromDays(sortedValue)
      setRepeats(getRepeatOption(newRepeat))
    },
    [onChange, getRepeatOption]
  )

  return {
    repeats,
    handleRepeatsChange,
    handleDaysChange,
    getRepeatOption
  }
}
